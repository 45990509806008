<template>
    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
         aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div
                class="modal modal-manager inline-block align-bottom rounded-lg px-4 sm:pt-8 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 max-h-screen">
                <div class="h-full">
                    <button @click="close()" type="button" class="close-button">
                        <span class="sr-only">{{ $t('translations.global.close') }}</span>
                        <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div class="h-full">
                        <h3 class="mb-5 text-lg leading-6 font-medium" id="modal-title">
                            {{ $t('translations.modal.manager-itemtoggle.title') }}
                        </h3>
                            <div class="flex flex-col h-full overflow-auto stretcher rounded-md">
                                <div class="shadow border-b border-gray-200">
                                    <table class="min-w-full divide-y divide-gray-200">
                                        <tbody v-for="(category, category_index) in categories"
                                               :key="category_index">
                                            <tr class="bg-white">
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {{ category.attributes.name }}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr v-for="(item, item_index) in category.relationships.items.data"
                                                :key="item_index" class="bg-white">
                                                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {{ item.attributes.description }}
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                                    <button v-if="item.attributes.kiosk_active"
                                                            @click="toggle(item, false)" type="button"
                                                            class="btn deactivate">
                                                        {{
                                                            $t('translations.modal.manager-itemtoggle.button-deactivate')
                                                        }}
                                                    </button>
                                                    <button v-else @click="toggle(item, true)" type="button"
                                                            class="btn activate">
                                                        {{
                                                            $t('translations.modal.manager-itemtoggle.button-activate')
                                                        }}
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        <router-link :to="{name:'boot'}"
                                     class="mt-5 btn btn-primary inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                            {{ $t('translations.modal.manager-itemtoggle.button-reboot') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// libs
import db from "../lib/db";
import * as _api from '../lib/api';
import * as _state from "../lib/state";

export default {
    name: 'modal.manager.items',
    data () {
        return {
            active: false,
            categories: [],
        };
    },
    methods: {
        open () {

            // load the items
            this.getCategories();

            this.active = true;
        },

        close () {
            this.active = false;
        },

        async getCategories () {
            this.categories = await db.get('categories');
        },

        async toggle (item, status) {

            let response = await _api.post('items/toggle-activity', {
                item_id: item.id,
                source: 'kiosk',
                active: status,
            });

            // handle the response
            if (response && response.result === 'success') {
                item.attributes.kiosk_active = status;

                return true;
            }
        },
    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
    },
};
</script>
