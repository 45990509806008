<template>
    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto modal-help" aria-labelledby="modal-title" role="dialog" aria-modal="true">   
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div> <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div class="content" v-if="question">
                    <h3 class="mb-4">{{ $t('translations.modal.support.title') }}</h3>
                    <p>{{ $t('translations.modal.support.confirm-question') }}</p>
                    <p class="mb-4">{{ $t('translations.modal.support.description') }}</p>
                    <div class="buttons-wrapper">
                        <button class="btn btn-primary" @click="getSupportEmployees">{{ $t('translations.modal.support.yes') }}</button>
                        <button class="btn btn-danger" @click="cancel">{{ $t('translations.modal.support.no') }}</button>
                    </div>
                </div>
                
                <div v-if="check" class="text-center">
                    <h3 class="mb-4">{{ $t('translations.modal.support.support-feedback.title') }}</h3>
                    <p class="mb-4">{{ $t('translations.modal.support.support-feedback.notification-sentence') }}<br>{{ $t('translations.modal.support.support-feedback.description') }}</p>
                    <button class="btn btn-primary w-full" @click="closeModal">{{ $t('translations.modal.support.support-feedback.ok') }}</button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="errorModal" class="fixed z-100 inset-0 overflow-y-auto modal-help" aria-labelledby="modal-title" role="dialog" aria-modal="true">   
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div><span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <h3 class="mb-4">{{ $t('translations.modal.support.errors.title') }}</h3>
                <div class="buttons-wrapper">                
                    <button @click="closeErrorModal" class="btn btn-primary">Close</button>
                </div>
            </div>
        </div>
    </div>

    
</template>

<script>
import * as _api from '../lib/api';
import * as _state from "../lib/state";

export default {
    data(){
        return {
            active: false,
            question: false,
            check: false,
            errorModal: false,
        }
    },
    methods: {
        showModal(){
            this.active = true;
            this.question = true;
            _state.setField('status/getStatus', 'status/setStatus', 'autocancel_disabled', true);
        },
        async getSupportEmployees(){

            let response = await _api.post('getsupport', {type: 'support_kiosk'});

            if(response.result == 'success'){
                this.question = false;
                this.check = true;
                return;
            }
            
            this.errorModal = true;
            this.active = false;

            _state.setField('status/getStatus', 'status/setStatus', 'autocancel_disabled', false);
        },
        closeModal(){
            this.active = false;
            this.check = false;
            _state.setField('status/getStatus', 'status/setStatus', 'autocancel_disabled', false);
        },
        closeErrorModal(){
            this.errorModal = false;
            this.check = false;
            _state.setField('status/getStatus', 'status/setStatus', 'autocancel_disabled', false);
        },
        cancel(){
            this.active = false;
            _state.setField('status/getStatus', 'status/setStatus', 'autocancel_disabled', false);
        },
    }
}
</script>