<template>
    <div class="modal modal-full padding-wrapper transform payment-modal" :class="'pending-' + config.theme.pin_terminal_position">
        <div v-if="config.theme.device == 'S1U2'" class="modal-inner text-center">
            <div class="payment-icon">
                <div class="payment-icon-inner">
                    <svg width="800px" height="800px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" >

                        <path d="M0 0h48v48H0z" fill="none"/>
                        <g id="Shopicon">
                            <path d="M13,4h-3C7.8,4,6,5.8,6,8v23.955c0,2.2,1.8,4,4,4h3V4z"/>
                            <path d="M40.228,21.494L30,11.267V8c0-2.2-1.8-4-4-4h-7v31.955h7c2.2,0,4-1.8,4-4v-3.712l2.12,2.12l2.829-2.828l-4.663-4.663
                                l-6.301-6.301c-0.378-0.378-0.585-0.884-0.584-1.424c0.001-0.534,0.207-1.033,0.584-1.408c0.754-0.755,2.079-0.753,2.832,0
                                L31,17.923l6.416,6.416l-0.072-0.022l0.109,0.072C37.584,24.586,38,25.337,38,26.955v13H22c-1.915,0-3.605-1.633-3.933-3.799
                                L18.044,36h-4.045l0.114,0.754c0.621,4.105,4.012,7.201,7.888,7.201h20v-17C42,23.483,40.656,21.907,40.228,21.494z"/>
                        </g>
                    </svg>
                </div>
            </div>
        </div>

        <div v-else class="modal-inner text-center">
            <h1 class="text-center">Pending....</h1>

            <div class="cancel-payment-invert text-2xl">
                {{ $t('translations.payment.cancel-description') }}
            </div>

            <div class="button-flexer flexer-center">
                <a id="cancelbutton" class="cancel-payment btn btn-secondary" :disabled="is_canceling === true"
                   @click="paymentCancel()">
                    <div class="mr-4" v-if="is_canceling === true">
                        <svg class="animate-spin h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                    {{ $t('translations.payment.cancel') }}
                </a>
            </div>

            <div class="payment-icon loading">
                <div class="payment-icon-inner">
                    <svg width="510px" height="488px" viewBox="0 0 510 488" version="1.1"
                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Line 12</title>
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                           stroke-linecap="round">
                            <g id="Line-12" transform="translate(15.000000, 15.000000)" stroke="#FFF200"
                               stroke-width="30">
                                <path
                                    d="M0,134 L239.357,334.96 C239.729,335.272 240.271,335.272 240.643,334.96 L480,134"
                                    id="Path"></path>
                                <path
                                    d="M0,-1.42108547e-14 L239.357,200.96 C239.729,201.272 240.271,201.272 240.643,200.96 L480,-1.42108547e-14"
                                    id="Path"></path>
                                <path
                                    d="M0,256 L239.357,456.96 C239.729,457.272 240.271,457.272 240.643,456.96 L480,256"
                                    id="Path"></path>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as _payment from '../lib/payment';
import * as _state from "../lib/state";

// mixins
import PaymentHelper from '../mixins/payment.helper.js';

export default {
    name: 'payment.pending',
    mixins: [PaymentHelper],
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
    }
};
</script>
