<template>
    <div v-if="payment">
        <payment-open v-if="payment.status == 'open'" />
        <payment-pending v-if="payment.status == 'pending'" />
        <payment-pending-redirect v-if="payment.status == 'redirect'" />
        <payment-failed v-else-if="payment.status == 'failed'" @tryagain="paymentTryAgain()" @cancel="paymentCancel()" />
        <payment-success v-else-if="payment.status == 'paid'" />
    </div>
</template>

<script>
// libs
import * as _payment from '../lib/payment';
import * as _transaction from '../lib/transaction';
import * as _state from "../lib/state";
import * as _log from '../lib/log';

// components
import PaymentFailed from '../components/PaymentFailed.vue';
import PaymentOpen from '../components/PaymentOpen.vue';
import PaymentPending from '../components/PaymentPending.vue';
import PaymentPendingRedirect from '../components/PaymentPendingRedirect.vue';
import PaymentSuccess from '../components/PaymentSuccess.vue';

// set the logger
const logger = _log.get('PAYMENT');

export default {
    name: 'payment',
    props: ['paymenttype_id', 'paymenttype_name', 'paymenttype_mode', 'status'],
    components: {
        PaymentFailed,
        PaymentOpen,
        PaymentPending,
        PaymentPendingRedirect,
        PaymentSuccess,
    },
    data () {
        return {
            loading: false,
            paymenttypes: [],
            status_counts: 0,
        }
    },
    async mounted () {
        if (this.payment && this.payment.id) {
           logger.log('payment exist')();

            if (this.payment.status == 'redirect') {
               await this.paymentStatus();
           }
        }
        else if (!this.paymenttype_id || !this.paymenttype_name) {
            this.$router.replace({name: 'checkout'});
        }
        else {
            // start payment
            this.paymentStart(this.transaction.id, this.paymenttype_id, this.paymenttype_name, this.paymenttype_mode, this.transaction.total_unpaid)
        }
    },

    methods: {
        // todo: merge paymentStart and paymentTryAgain
        async paymentStart() {
            let response = await _payment.init(this.transaction.id, this.paymenttype_id, this.paymenttype_name, this.paymenttype_mode, this.transaction.total_unpaid);

            // if (response === false) {
            //     console.log('payment is false');
            //     let status = await _payment.getStatus();
            //     console.log('status', status)

            //     if (status === false || status == 'open' || status == 'failed') {
            //         console.log('payment cancel the paymnt')
            //         _payment.setStatus('failed');
            //         // await this.paymentCancel()
            //     }
            // }
        },

        paymentTryAgain() {
            _payment.init(this.transaction.id, this.payment.paymenttype_id, this.payment.paymenttype_name, this.payment.paymenttype_mode, this.transaction.total_unpaid);
        },

        async paymentCancel() {
            // cancel the already stored transaction
            await _transaction.cancelStoredTransaction();

            // set the id of the payment to null
            _payment.setId(null);


            if (this.config.theme.device === 'S1U2') {
                await _transaction.cancel(true, false);
                _payment.clear();
            }

            this.$router.replace({ name: 'checkout' });
        },

        async paymentStatus() {

            this.status_counts++;

            let response = await _payment.getStatus();

            if (response === false) {
                logger.error('response error')();

                return;
            }

            // todo: change status open/redirect
            if (this.payment.id === this.$route.query.id && (this.payment.status == 'open' || this.payment.status == 'redirect')) {
                _payment.setStatus(response);

                if (response === 'open') {
                    if (this.status_counts <= 3) {
                        window.setTimeout(this.paymentStatus, 10000);
                    }
                }
            } else {
                logger.error('payment_id not equal, payment_id:', this.payment.id)();
            }
        },
    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },

        payment() {
            return _state.get('payment/getPayment')
        },
        transaction() {
            return _state.get('transaction/getTransaction')
        },
    }
}
</script>
