import * as _log from "./log";
import * as _state from "./state";

const logger = _log.get("i18n");

export const getMessages = () => {

    let locales = { en: {}, nl: {}};

    locales['nl'] = require('../locales/nl-NL.json');
    locales['en'] = require('../locales/en-US.json');

    logger.log('translations loaded')();

    return locales;
}

export const getNumberFormats = () => {

    return {
        'nl': {
            currency: {
                style: 'currency', currency: 'eur', notation: 'standard'
            },
            eur: {
                style: 'currency',
                currency: 'eur',
                notation: 'standard',
            },
            usd: {
                style: 'currency',
                currency: 'usd',
                notation: 'standard',
            },
            gbp: {
                style: 'currency',
                currency: 'gbp',
                notation: 'standard',
            },
            chf: {
                style: 'currency',
                currency: 'chf',
                notation: 'standard',
            },
            decimal: {
                style: 'decimal', minimumSignificantDigits: 3, maximumSignificantDigits: 5
            },
            percent: {
                style: 'percent', useGrouping: false
            }
        }
    }
}
