import { getToken } from "../lib/api";
import * as _state from "../lib/state";
import * as _payment from "../lib/payment";
import * as _log from '../lib/log';

import Echo from "laravel-echo";

window.Pusher = require('pusher-js');

// set the logger
const logger = _log.get('WEBSOCKETS');

const connect = async (channel) => {

    logger.log('connecting...')();

    disconnect();

    let token = await getToken();

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_PUSHER_KEY,
        cluster: 'eu',
        encrypted: true,
        authEndpoint: process.env.VUE_APP_API_URL + 'v1/kiosk/broadcasting/auth',
        auth: { headers: { Authorization: 'Bearer ' + token } },
    });
    logger.log('connected')();
}

const disconnect = () => {

    if (window.Echo) {

        logger.log('disconnection...')();

        window.Echo.disconnect();

        logger.log('disconnected...')();
    }
}

const connectWebsockets = async () => {

    // connect
    await connect();

    logger.log('opening...')();

    // get the config
    let config = _state.get('config/getConfig')

    // set channel
    // todo set to terminal
    let channel = 'paymentstatus.' + config.terminal_id;
    // let channel = 'terminal.' + config.terminal_id;
    // payment.setStatus('success');
    // listen to channel
    window.Echo.private(channel)
        .listenToAll((event, data) => {
            logger.log('message received:', data)();
            // do what you need to do based on the event name and data
            if (data.message == 'payment.status') {

                let payment = _state.get('payment/getPayment');

                if (payment.id === data.payment.id) {

                    let status = data.payment.attributes.status

                    _payment.setStatus(status);

                } else {
                    logger.error('payment_id not equal, payment_id:', payment.id)();
                    // todo: not equal
                }
            }
            else if (data.message == 'terminal.reboot') {

                if (data.force === true) {

                    _state.wipeStates();

                    window.location = '/login';

                    return;
                }

                let config = _state.get('config/getConfig');

                config.reboot = true;

                _state.set('config/setConfig', config);

            }
        });

    logger.log('listening to channel', channel)();
}

export {
    connectWebsockets,
}
