<template>
    <div class="main-wrapper">
        <navigation />
        <div class="flex grid-height barcode-scanner">
            <div class="flex-grow padding-wrapper large-buttons">
                <h1 class="mb-8"> {{ $t('translations.barcodescanner.title') }}</h1>

                <router-link :to="{name:'menu.selection'}" class="btn btn-primary mr-4">
                     {{ $t('translations.barcodescanner.button-menu') }}
                </router-link>

                <!-- adds the abbility to scan barcodes -->
                <scanner :hideFakeScan="true"/>

            </div>

            <div class="basket-wrapper">
                <basket />
            </div>

            <!-- check for activity -->
        </div>
    </div>
</template>

<script>
// components
import Basket from '../components/Basket.vue';
// import Idle from '../components/Idle.vue';
import Navigation from '../components/Navigation.vue';
import Scanner from '../components/Scanner.vue';

export default {
    name: 'barcode.scanner',
    components: {
        Basket,
        Navigation,
        Scanner,
    },
}
</script>
