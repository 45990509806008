<template>
    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div> <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                    <button @click="close()" type="button" class="close-button">
                        <span class="sr-only">{{ $t('translations.global.close') }}</span>
                        <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div class="mt-3 sm:mt-5">
                        <h3 class="mb-5 text-lg leading-6 font-medium" id="modal-title">
                            {{ $t('translations.modal.manager-transactions.title') }}
                        </h3>
                        <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div class="border-t border-gray-200">
                                <div class="flex flex-col">
                                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200">
                                                    <thead class="bg-gray-50">
                                                        <tr>
                                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                {{ $t('translations.modal.manager-transactions.headings.transactionnumber') }}
                                                            </th>
                                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                {{ $t('translations.modal.manager-transactions.headings.total') }}
                                                            </th>
                                                            <th scope="col" class="relative px-6 py-3"></th>
                                                            </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(transaction, index) in transactions" :key="index" class="bg-white">
                                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                {{ transaction.attributes.transactionnumber }}
                                                            </td>
                                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                {{ transaction.attributes.total }}
                                                            </td>
                                                            <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                                <a @click="reprint(transaction)" class="text-indigo-600 hover:text-indigo-900">
                                                                    {{ $t('translations.modal.manager-transactions.reprint') }}
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// libs
import * as _api from "../lib/api";
import * as _printer from "../lib/printer";
import * as _state from "../lib/state";
import * as _transaction_receipt from "../lib/receipts/transaction";

export default {
    name: 'modal.manager.transactions',
    data () {
        return {
            active: false,
            transactions: [],
        };
    },
    methods: {
        open() {

            // load the transactions
            this.getTransactions();

            this.active = true;
        },

        close() {
            this.active = false;
        },

         async getTransactions() {
            this.transactions = await _api.get('transaction/latest');
                // // // print
                // _printer.print(_transaction_receipt.getRequest(data[0]));
        },

        reprint(transaction) {
            _printer.print(_transaction_receipt.getRequest(transaction, true));
        },

    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
    },
};
</script>
