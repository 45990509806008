<template>
    <div v-if="status && status.ordertime_exceeded" class="fixed z-100 inset-0 overflow-y-auto"
         aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal modal-full transform padding-wrapper">

                <div class="modal-inner">
                    <h1 class="" id="modal-title">
                        {{ $t('translations.modal.order-time-exceed.title') }}
                    </h1>
                     <p>{{ $t('translations.modal.order-time-exceed.description') }}</p>
                    <div class="button-flexer">
                        <button type="button" @click="cancelTransaction()" class="inline-flex btn btn-secondary justify-center w-full">
                            {{ $t('translations.modal.order-time-exceed.button-confirm') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// libs
import * as _log from '../lib/log';
import * as _state from '../lib/state';
import * as _transaction from '../lib/transaction';

// set the logger
const logger = _log.get('ModalOrderExceeded');

export default {
    name: 'modal.order.time-exceeded',
    methods: {
        cancelTransaction () {
            logger.log('transaction canceled');

            _state.setField('status/getStatus', 'status/setStatus', 'ordertime_exceeded', false);

            _transaction.cancel();

            this.$router.replace({ name: 'landing' });
        },
    },
    computed: {
        status () {
            return _state.get('status/getStatus');
        },
    },
};
</script>
