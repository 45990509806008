<template>
    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
         aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal modal-full transform padding-wrapper">
                <div class="modal-inner">
                    <h1 class="" id="modal-title">
                        {{ $t("translations.status.inactive") }}?
                    </h1>

                    <div class="button-flexer">
                        <button type="button" @click="continueTransaction()" class="btn btn-primary">
                            {{ $t("translations.global.continue") }}
                        </button>

                        <button type="button" @click="cancelTransaction()" class="btn btn-secondary">
                            {{ $t("translations.global.cancel") }}
                            ({{ countdown_seconds }})
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <modal-manager-configuration ref="configuration"></modal-manager-configuration>
    </div>

</template>

<script>

// libs
import * as _log from '../lib/log';
import * as _state from "../lib/state";

// mixins
import IntervalTimeout from '../mixins/interval-timeout.helper.js';

// components

// set the logger
const logger = _log.get('Idle');

export default {
    name: 'idle',
    mixins: [IntervalTimeout],
    components: {},
    data () {
        return {
            active: false,
            timeout_ids: [],
            interval_ids: [],
            idletime: 60,
            countdown_seconds: 15,
        };
    },
    mounted () {
        document.onmousemove = this.resetTimer;
        document.onclick = this.resetTimer;
        document.onkeydown = this.resetTimer;
        this.resetTimer();
    },
    unmounted () {
        document.onmousemove = false;
        document.onclick = false;
        document.onkeydown = false;

        this.clearAllTimeouts();
        this.clearAllIntervals();
    },
    methods: {
        open () {
            this.active = true;
            this.countdown_seconds = 15;
            this.countdown();
        },

        close () {
            this.active = false;
            // this.clearAllIntervals();
        },

        resetTimer () {
            if (this.active === false) {
                this.clearAllTimeouts();
                // clearTimeout(this.timeout_id);

                let duration = this.config.theme.autocancel_duration ? this.config.theme.autocancel_duration : 30;

                let timeout_id = window.setTimeout(this.openIdleModal, duration * 1000);

                this.timeout_ids.push(timeout_id);
            }
        },

        cancelTransaction () {
            logger.log('canceled')();

            this.close();

            this.clearAllTimeouts();
            this.clearAllIntervals();

            this.$router.push({ name: 'landing' });
        },

        continueTransaction () {
            logger.log('continue')();

            // this.clearAllTimeouts();
            this.clearAllIntervals();

            this.close();
            this.resetTimer();
        },

        openIdleModal () {
            logger.log('idle state triggered')();

            this.open();
        },

        countdown () {
            this.clearAllIntervals();

            let interval_id = setInterval(() => {
                if (this.countdown_seconds === 1) {
                    this.cancelTransaction();
                }
                this.countdown_seconds -= 1;
            }, 1000);

            this.interval_ids.push(interval_id);
        },
    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
    },
};
</script>
