<template>
    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto"
         aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal modal-full transform padding-wrapper">
                <div class="modal-inner">
                    <h1 class="" id="modal-title">
                        {{ $t('translations.modal.item-unavailable.title') }}
                    </h1>
                    <p>{{ $t('translations.modal.item-unavailable.description') }}</p>
                    <div class="button-flexer">
                        <button type="button" @click="close()"
                                class="inline-flex justify-center w-full btn btn-secondary">
                            {{ $t('translations.modal.item-unavailable.button-close') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'modal.item.unavailable',
    components: {},
    data () {
        return {
            active: false,
        };
    },
    methods: {
        open () {
            this.active = true;
        },

        close () {
            this.active = false;
        },
    },
};
</script>
