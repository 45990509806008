<template>
    <div>v{{ version }}</div>
</template>
<script>

import {version} from '../../package.json'

export default {
    name: 'version',
    computed: {
        version () {
            return version;
        },
    },
};
</script>
