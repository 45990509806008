export default {
    methods: {

        clearAllTimeouts() {

            let len = this.timeout_ids.length;

            while (len > 0) {
                const id = this.timeout_ids[len - 1];
                clearTimeout(id);
                len--;
            }

            this.timeout_ids = [];
        },

        clearAllIntervals() {
            let len = this.interval_ids.length;

            while (len > 0) {
                const id = this.interval_ids[len - 1];
                clearTimeout(id);
                len--;
            }

            this.interval_ids = [];
        },
    }
}
