import * as _payment from '../lib/payment';
import * as _transaction from "../lib/transaction";

export default {
    data() {
        return {
            is_canceling: false,
        }
    },
    methods: {
        async gotoPayment (id, name, mode) {

            if (this.busy === true) {
                return;
            }
            this.busy = true;

            let response = await _transaction.send();

            if (response === true) {
                this.$router.push({
                    name: 'payment',
                    params: {
                        paymenttype_id: id,
                        paymenttype_name: name,
                        paymenttype_mode: mode,
                    },
                });
            } else {
                // todo notification
            }
            this.busy = false;
        },

        async paymentCancel() {

            this.is_canceling = true;

            let status = await _payment.getStatus();

            if (status == 'open') {
                let response = await _payment.cancel();

                if (response === true) {

                    setTimeout(function() {
                        _payment.setStatus('failed');

                        this.is_canceling = false;
                    }, 3000);
                } else {
                    this.is_canceling = false;
                }

                return;
            }

            _payment.setStatus(status);
        },
    }
}
