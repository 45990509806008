<template>
    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div> <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal modal-wide inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                    <button @click="close()" type="button" class="close-button">
                        <span class="sr-only">{{ $t('translations.global.close') }}</span>
                        <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div class="mt-3 sm:mt-5">
                        <h3 class="mb-5 text-lg leading-6 font-medium" id="modal-title">
                            {{ $t('translations.modal.manager-areaselection.title') }}
                        </h3>
                        <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div class="border-t border-gray-200">
                                <div class="flex flex-col">
                                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-200">
                                                    <thead class="bg-gray-50">
                                                        <tr>
                                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                {{ $t('translations.modal.manager-areaselection.headings.area') }}
                                                            </th>
                                                            <th scope="col" class="relative px-6 py-3"></th>
                                                            </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(area, index) in area" :key="index" class="bg-white">
                                                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                {{ area.attributes.name }}
                                                            </td>
                                                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                <button type="button" @click="selectArea(area)" class="float-right px-5 text-lg leading-5 rounded-sm border p-1">
                                                                   {{ $t('translations.modal.manager-areaselection.button-select') }}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// libs
import * as _api from "../lib/api";
import * as _printer from "../lib/printer";
import * as _state from "../lib/state";

export default {
    name: 'modal.manager.areaselection',
    data () {
        return {
            active: false,
            area: [],
        };
    },
    methods: {
        open() {

            // load the area
            this.getArea();

            this.active = true;
        },

        close() {
            this.active = false;
        },

         async getArea() {
            this.area = await _api.get('areas');
        },

        async selectArea(area) {
            // update area of terminal
            let response = await _api.post('terminal/set-area', {
                area_id: area.id,
            });

            // handle the response
            if (response && response.result === 'success') {
                this.$router.replace({ name: 'boot' })
            }
        },

    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
    },
};
</script>
