<template>
    <div class="numpad">
        <div class="grid grid-cols-3 mt-2 gap-2">
            <span class="key col-span-1 inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('7')" class="btn btn-numpad">
                    7
                </a>
            </span>
            <span class="key col-span-1 inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('8')" class="btn btn-numpad">
                    8
                </a>
            </span>
            <span class="key col-span-1 inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('9')" class="btn btn-numpad">
                    9
                </a>
            </span>
        </div>

        <div class="grid grid-cols-3 mt-2 gap-2">
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('4')" class="btn btn-numpad">
                    4
                </a>
            </span>
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('5')" class="btn btn-numpad">
                    5
                </a>
            </span>
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('6')" class="btn btn-numpad">
                    6
                </a>
            </span>
        </div>

        <div class="grid grid-cols-3 mt-2 gap-2">
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('1')" class="btn btn-numpad">
                    1
                </a>
            </span>
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('2')" class="btn btn-numpad">
                    2
                </a>
            </span>
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('3')" class="btn btn-numpad">
                    3
                </a>
            </span>
        </div>

        <div class="grid grid-cols-3 mt-2 gap-2">
            <span class="key inline-flex rounded-sm  shadow-sm">
            </span>
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="setInput('0')" class="btn btn-numpad">
                    0
                </a>
            </span>
            <span class="key inline-flex rounded-sm  shadow-sm">
                <a @click="clearInput()" class="btn btn-numpad">
                    Clear
                </a>
            </span>
        </div>
    </div>

</template>

<script>
export default {
    name: 'numpad',
    props: ['defaultvalue'],
    data () {
        return {
            value: this.defaultvalue,
        };
    },
    mounted () {
        if (this.value === undefined) {
            this.value = '';
        }
    },
    methods: {

        setInput(value) {


            this.value += value
            this.$emit('inputvalue', this.value)
        },

        setValue(value) {
            this.value = value;
        },

        clearInput() {
            this.value = ''
            this.$emit('inputvalue', this.value)
        },


    },
    computed: {
    },
};
</script>
