<template>
    <div>
        <div class="locale-changer">
            <!-- <select v-model="$i18n.locale">
              <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
            </select> -->
        </div>
        <div class="language-switcher">
            <a v-if="config.language !== 'en'" class="cursor-pointer flex flex-row-reverse justify-start"
               @click="setLanguage('en')">
                <img src="/img/flags/en.png" /> English
            </a>
            <a v-if="config.language !== 'nl'" class="cursor-pointer flex flex-row-reverse justify-start"
               @click="setLanguage('nl')">
                <img src="/img/flags/nl.png" /> Nederlands
            </a>
        </div>
    </div>
</template>

<script>
// libs
import * as _log from '../lib/log';
import * as _state from "../lib/state";

// set the logger
const logger = _log.get('LanguageSelection');

export default {
    name: 'language.selection',
    methods: {
        setLanguage (language) {
            this.$i18n.locale = language;

            // todo: check storage
            _state.setField('config/getConfig', 'config/setConfig', 'language', language);
        },
    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
    },
};
</script>
