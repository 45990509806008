<template>
    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto modal-locker-config-missing" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div> <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal modal-wide inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                    <button @click="close()" type="button" class="close-button">
                        <span class="sr-only">{{ $t('translations.global.close') }}</span>
                        <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div class="mt-3 sm:mt-5">
                        <h1 class="mb-5 text-xl leading-6 font-medium" id="modal-title">
                            {{ $t('translations.modal.manager-lockerconfig.title') }}
                        </h1>
                            <div class="border-t border-gray-200">
                                <div class="flex flex-col">
                                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

                                            <div v-if="step > 1" @click="previousStep()" class="btn previous-step">
                                                {{ $t('translations.modal.manager-lockerconfig.button-back') }}
                                            </div>

                                            <!-- events -->
                                            <div v-if="step == 1">
                                                <div v-if="events.length > 0" class="option-list radius-anchor">
                                                    <h2>{{ $t('translations.modal.manager-lockerconfig.title-event') }}</h2>
                                                    <div class="buttons">
                                                        <div v-for="(event, index) in events" :key="index" class="">
                                                            <a @click="selectEvent(event)" class="btn btn-secondary">
                                                                {{ event.attributes.name }} ({{ event.attributes.date }})
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div>{{ $t('translations.modal.manager-lockerconfig.no-events') }}</div>
                                                </div>
                                            </div>
                                            <!-- locations -->
                                            <div v-if="step == 2">
                                                <div v-if="locations.length > 0" class="option-list radius-anchor">
                                                    <h2>{{ $t('translations.modal.manager-lockerconfig.title-location') }}</h2>
                                                    <div class="buttons">
                                                        <div v-for="(location, index) in locations" :key="index" class="">
                                                            <a @click="selectLocation(location)" class="btn btn-secondary">
                                                                {{ location.attributes.name }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div>{{ $t('translations.modal.manager-lockerconfig.no-locations') }}</div>
                                                </div>
                                            </div>
                                            <!-- areas -->
                                            <div v-if="step == 3">
                                                <div v-if="areas.length > 0" class="option-list radius-anchor">
                                                    <h2>{{ $t('translations.modal.manager-lockerconfig.title-area') }}</h2>
                                                    <div class="buttons">
                                                        <div v-for="(area, index) in areas" :key="index" class="">
                                                            <a @click="selectArea(area)" class="btn btn-secondary">
                                                                {{ area.attributes.name }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div>{{ $t('translations.modal.manager-lockerconfig.no-areas') }}</div>
                                                </div>
                                            </div>

                                            <!-- overview -->
                                            <div v-if="step == 4">
                                                <div class="mb-4 text-xl">
                                                    <div><span class="font-bold">Event:</span> {{ data.event_name }} ({{data.event_date}})</div>
                                                    <div><span class="font-bold">Location:</span> {{ data.location_name }}</div>
                                                    <div><span class="font-bold">Area:</span> {{ data.area_name }}</div>
                                                </div>

                                                <div @click="onSubmit()" class="btn btn-primary">
                                                    {{ $t('translations.modal.manager-lockerconfig.button-submit') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// libs
import * as _api from "../lib/api";
import * as _printer from "../lib/printer";
import * as _state from "../lib/state";

export default {
    name: 'modal.manager.lockerconfiguration',
    data () {
        return {
            active: false,
            events: [],
            locations: [],
            areas: [],

            step: 1,
            data: {
                event_id: null,
                event_name: null,
                event_date: null,
                location_id: null,
                location_name: null,
                area_id: null,
                area_name: null,
            }
        };
    },
    methods: {
        open() {
            // load the events
            this.getEvents();

            this.active = true;
        },

        close() {
            this.active = false;
        },

        previousStep() {
            this.step--;
        },

         async getEvents() {
            this.events = await _api.get('elockers/events');
        },

        // async selectEvent(event) {

        //     // update event of terminal
        //     let response = await _api.post('terminal/set-event', {
        //         event_id: event.id,
        //     });

        //     // handle the response
        //     if (response && response.result === 'success') {
        //         this.$router.replace({ name: 'boot' })
        //     }
        // },



        selectEvent (event) {
            // _transaction.setElockersEvent(event.id, event.attributes.name);
            this.data.event_id = event.id;
            this.data.event_name = event.attributes.name;
            this.data.event_date = event.attributes.date;
            this.data.event_next = event.attributes.next;

            this.step = 2;

            this.locations = event.relationships.locations.filter(location => {
                return location.attributes.active;
            });

            // //automatically select the item in the next step if there's only 1 option
            if (this.locations.length === 1) {
                this.selectLocation(this.locations[0]);
            }
        },

        selectLocation (location) {

            // _transaction.setElockersLocation(location.id, location.attributes.name);

            this.data.location_id = location.id;
            this.data.location_name = location.attributes.name;
            this.step = 3;

            this.areas = location.relationships.areas.filter(area => {
                return area.attributes.active === true;
            })

            // automatically select the item in the next step if there's only 1 option
            if (this.areas.length === 1) {
                this.selectArea(this.areas[0]);
            }
        },

        async selectArea (area) {
            // logger.log('selected area', area)();

            // _transaction.setElockersArea(area.id, area.attributes.name);

            this.data.area_id = area.id;
            this.data.area_name = area.attributes.name;
            // this.data.area = area;
            this.step = 4;
        },

        onSubmit () {
            let config = this.config;

            config.integration_elockers_event_id = this.data.event_id,
            config.integration_elockers_event_name = this.data.event_name,
            config.integration_elockers_event_date = this.data.event_date,
            config.integration_elockers_event_next = this.data.event_next,
            config.integration_elockers_location_id = this.data.location_id,
            config.integration_elockers_location_name = this.data.location_name,
            config.integration_elockers_area_id = this.data.area_id,
            config.integration_elockers_area_name = this.data.area_name,

            _state.set('config/setConfig', config);

            this.close();
        },

    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
    },
};
</script>
