<template>
    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div> <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                    <button @click="close()" type="button" class="close-button">
                        <span class="sr-only">{{ $t('translations.global.close') }}</span>
                        <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div class="mt-3 sm:mt-5">
                        <h3 class="mb-5 text-lg leading-6 font-medium" id="modal-title">
                            {{ $t('translations.modal.manager-login.title') }}
                        </h3>

                        <!-- todo: display error message -->
                        <div class="mb-2" v-show="error_auth === true">Unauthenticated</div>
                        <div>
                            <input placeholder="PINCODE" v-model="pincode" autofocus name="pincode" type="password" required class="appearance-none rounded relative block w-full px-3 py-2 placeholder-gray-500 text-gray-900 outline-none"  />
                        </div>
                        <div class="mt-2 numpad-light">
                            <numpad @inputvalue="pincode = $event"></numpad>
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-6">
                    <button type="button" @click="login()" class="inline-flex btn-primary btn justify-center w-full">
                        {{ $t('translations.modal.manager-login.button-login') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as _bcrypt from 'twin-bcrypt';

// libs
import * as _state from "../lib/state";
import * as _log from '../lib/log';

// components
import Numpad from "./Numpad.vue";

// set the logger
const logger = _log.get('ModalManagerLogin');

export default {
    name: 'modal.manager.login',
    components: {
        Numpad,
    },
    data () {
        return {
            active: false,
            error_auth: false,
            loggedin: false,
            pincode: '',
        };
    },
    methods: {
        open() {
            this.pincode = '';
            this.active = true;
        },

        close() {
            this.pincode = '';
            this.active = false;
        },

        login() {
            this.error_auth = false;

            if (_bcrypt.compareSync(this.pincode, this.config.pincode)) {
                logger.log('manager logged in')();

                this.close();

                this.$emit('loggedIn');
            } else {
                this.error_auth = true;
            }
        },

    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
    },
};
</script>
