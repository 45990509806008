<template>
    <div>
        <div id="item" v-if="item.attributes.available_in_inventory == true"
             :class="[config.theme.add_product_icon, {'item-adding' : item_adding}]"
             class="item">

            <div v-if="item_adding" class="item-adding-wrapper">

                <div class="item-adding-fader"></div>
                <div class="item-adding-svg-wrapper">
                    <svg
                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            </div>


            <div class="item-inner item-styles br-receiver" :class="{'has-image': item.attributes.thumbnail_url}">

                <div class="glow" v-if="config.theme.user_mode === 'snoeien'"></div>

                    <div class="info-icon">
                        <svg @click="tileClicked(true)" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                             viewBox="0 0 460 460" style="enable-background:new 0 0 460 460;" xml:space="preserve">
                            <g id="XMLID_1055_">
                            <g>
                                <path d="M230,0C102.975,0,0,102.975,0,230s102.975,230,230,230s230-102.974,230-230S357.025,0,230,0z M268.333,377.36
                                    c0,8.676-7.034,15.71-15.71,15.71h-43.101c-8.676,0-15.71-7.034-15.71-15.71V202.477c0-8.676,7.033-15.71,15.71-15.71h43.101
                                    c8.676,0,15.71,7.033,15.71,15.71V377.36z M230,157c-21.539,0-39-17.461-39-39s17.461-39,39-39s39,17.461,39,39
                                    S251.539,157,230,157z" />
                            </g>
                        </g>
                </svg>
                    </div>

                    <div @click="tileClicked()" class="item-content">

                        <div v-if="item.attributes.thumbnail_url" class="flex justify-center image-no-icon">
                            <item-image v-if="config.theme.user_mode === 'watch-only'" :img="item.attributes.image_url" />
                            <item-image v-else :img="item.attributes.thumbnail_url" />
                        </div>

                        <div v-if="item.attributes.svg_url" class="flex justify-center inline-svg-wrapper br-receiver">
                            <inline-svg
                                :src="item.attributes.svg_url"
                                class="inline-svg"
                            ></inline-svg>
                        </div>

<!--                        todo: fix boolean-->
                        <div class="flex justify-center tile-content-text" :class="['text-' + config.theme.product_text_style]">
                            <div class="font-bold name" v-if="config.theme.show_product_name === true">
                                {{
                                    item.attributes.description_translations[language] ? item.attributes.description_translations[language] : item.attributes.description
                                }}
                            </div>
                            <div class="font-normal opacity-80 mt-2 price" v-if="config.theme.show_product_price === true">
                                {{ $n(item.attributes.kiosk_price, currency) }}
                            </div>
                            <div class="mt-2" v-if="config.theme.product_description === 'default'">
                                {{ item.attributes.kiosk_description_translations[language] }}
                            </div>
                            <div class="mt-2" v-if="config.theme.product_description === 'long'">
                                {{ item.attributes.kiosk_description_long_translations[language] }}
                            </div>
                        </div>
                    </div>

                    <button v-if="!item.watchOnly" @click="tileClicked()" type="button"

                            class="btn btn-primary text-xl inline-flex items-center px-6 py-1.5 leading-4 font-medium">
                        <svg v-if="!loading" width="20" height="20" viewBox="0 0 20 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z"
                                  fill="currentColor" />
                        </svg>
                        <svg v-else class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </button>
            </div>
        </div>



        <div id="item" v-if="item.attributes.available_in_inventory == false"
             :class="config.theme.add_product_icon"
             class="opacity-50 col-span-1 justify-center item">
            <div class="item-inner item-styles br-receiver">
                <div class="item-content">
                    <div v-if="item.attributes.thumbnail_url" class="flex justify-center">
                        <img class="" :src="item.attributes.thumbnail_url">
                    </div>
                    <div class="flex justify-center tile-content-text" :class="['text-' + config.theme.product_text_style]">
                        <div class="font-bold truncate line-through name">{{ item.attributes.description }}
                        </div>
                        <div class="font-normal opacity-80 mt-2 price">
                            {{ $n(item.attributes.kiosk_price, currency) }}
                        </div>
                    </div>
                </div>
                <div class="text-s italic out-of-stock">{{ $t('translations.item.outofstock') }}</div>
            </div>
        </div>
    </div>

    <!-- handle the item: show popup or add to basket -->

    <item @toggleScan="this.$emit('toggleScan')"  @openWalletAddCreditModal="this.$emit('openWalletAddCreditModal', $event)" :watchOnly="item.watchOnly" ref="item" />

</template>

<style scoped>
body {
    position: fixed;
}

</style>

<script>
// libs
import * as _state from "../lib/state";
import db from "../lib/db";

// components
import Item from '../components/Item.vue';
import ItemImage from "./ItemImage";
import InlineSvg from 'vue-inline-svg';
import PaymentHelper from '../mixins/payment.helper.js';


export default {
    name: 'item.tile',
    emits: ['toggleScan', 'openWalletAddCreditModal'],
    mixins: [PaymentHelper],
    props: ['item', 'loading'],
    components: {
        Item,
        InlineSvg,
        ItemImage,
    },
    data () {
        return {
            show_description: false,
            payment_pin: null,
        }
    },
    mounted() {
        // TODO: move setPinPayment to the boot file
        this.setPinPayment();
    },
    methods: {
        async tileClicked (force_modal = false) {
            if (this.loading) {
                return;
            }

            this.$emit('loading', true);

            if (force_modal) {
                this.$emit('toggleScan');
            }

            if (!this.item.watchOnly || force_modal) {
                await this.$refs.item.addItem(this.item, force_modal);
            }

            // console.log(this.item);

            if (this.config.theme.device == 'S1U2' && this.payment_pin) {

                _state.setField('status/getStatus', 'status/setStatus', 'item_adding', true);

                await this.gotoPayment(this.payment_pin.id, this.payment_pin.attributes.name, this.payment_pin.attributes.psp_mode)
            }
            else if (this.config.theme.user_mode === 'nfc-online' && this.item.card) {
                // return without emit loading false
                console.log(this.item);

                return;
            }

            this.$emit('loading', false);
        },

        async setPinPayment () {
            if (this.config.theme.device !== 'S1U2') {
                return;
            }

            await db.initialize();

            let paymenttypes = await db.get('paymenttypes');

            this.payment_pin = paymenttypes.find(payementtype => payementtype.attributes.preferred_payment === true
                && (['adyen_pos', 'ccv_pin', 'adyen']).includes(payementtype.attributes.psp_type));
        },
    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },

        // loading () {
        //     let status = _state.get('status/getStatus');

        //     return status.item_loading == this.item.id;
        // },

        item_adding () {
            let status = _state.get('status/getStatus');

            return status.item_adding;
        },

        language () {
            return this.config.language;
        },

        currency(){
            return this.config.currency;
        },
    },
};
</script>
