<template>

    <div class="main-wrapper" :class="[{'watch-only-main': watchOnly},config.theme.user_mode]">
        <navigation :backbutton="backbutton" />

        <div class="grid-organizer"
             :class="{'watch-only': watchOnly,
              'has-products-in-cart': transaction.lines.length > 0}">

            <div class="flex categories relative" :class="{'slider' : categories.length > sliderVersion.perPage}"
                v-if="hide_categories === false && config.theme.device !== 'S1U2'"
            >

                <splide :options="sliderVersion">
                    <splide-slide
                        @click="categorySelect(category)"
                        :class="[{ 'active bg-gray-100': category.id == category_selected }, isChildCategoryClass(category)]"
                        class="category br-receiver tile-category-styles font-bold flex justify-center items-center"
                        v-for="(category, index) in categories" v-bind:key="index">

                        <div class="category-inner">
                            <div class="inline-svg-wrapper-category" :class="{'has-icon br-receiver': category.attributes.svg_url}">
                                <inline-svg
                                    :src="category.attributes.svg_url"
                                    class="inline-svg-category"
                                >
                                </inline-svg>
                            </div>
                            <img :src="category.attributes.thumbnail_url" alt="" class="category-image-no-icon"
                                :class="{'opacity-0': !category.attributes.thumbnail_url}">

                            <span v-if="category.relationships.items" :class="isChildCategoryClass(category)">
<!--                                {{ category.relationships.items.data[0] }}-->
<!--                                {{ category.relationships.items.data }}-->
                            </span>

<!--                            <span v-if="category.relationships.items">-->
<!--                                First check-->
<!--                                <span v-if="category.relationships.items.data[0]">-->
<!--                                    Second check-->
<!--                                    <div v-if="category.relationships.items.data[0].attributes.category_ids.length">-->
<!--                                        Third check-->
<!--                                        <span style="color: red">{{ category.relationships.items.data[0].attributes.category_ids }}</span>-->
<!--                                    </div>-->
<!--                                </span>-->
<!--                            </span>-->

                            <p>
                                {{ category.attributes.name_translations[language] ? category.attributes.name_translations[language] : category.attributes.name }}
                            </p>
                        </div>
                    </splide-slide>
                </splide>
            </div>

            <div class="flex items-stretch justify-between content-wrapper"
            :class="config.theme.product_ordering"
            >

                <div
                    class="items-wrapper-outer flex-grow flex flex-col justify-between overflow-y-auto overflow-x-hidden"
                    :class="
                        config.theme.interspace + ' ' +
                        config.theme.product_grid_decoration + ' '
                    ">

                    <div v-if="config.theme.product_ordering === 'overflow-horizontally' && watchOnly === true"
                         class="px-4 py-8  font-bold overflow-category-name text-center">
<!--                        hide instead of v-if for layout alignment purposes-->
                        <div class="text-5xl mb-4" :class="{'opacity-0 pointer-events-none': config.theme.show_category_name === false}">
                            {{ selected_category_name[language] }}
                        </div>


                        <div class="text-4xl w-4/5 mx-auto leading-normal"
                             v-if="config.theme.show_category_long_description"
                             v-html="selected_category_description_long[language]">
                        </div>
                    </div>


                    <div class="items-wrapper br-receiver overflow-y-auto"
                        :class="
                            'columns-' + config.theme.layout_columns + ' ' +
                            config.theme.tile_layout + ' ' +
                            config.theme.product_tile_size + ' ' +
                            config.theme.product_text_position + ' ' +
                            'items-'+ items.length
                        ">

                        <item-tile @toggleScan="this.scanToggle()" @openWalletAddCreditModal="openWalletAddCreditModal($event)" :loading="loading" @loading="loading = $event" :item="setItem(item)" v-for="(item, index) in items" :key="index" />
                    </div>

                    <information />

                    <!-- adds the abbility to scan barcodes -->
                    <scanner :scanDisabled="this.disableScan" :hideFakeScan="true" />

                </div>

                <div :class="{'config.theme.basket_width basket-wrapper' : !watchOnly}" v-if="config.theme.device !== 'S1U2'">
                    <basket :watch="watchOnly"/>
                </div>
            </div>
        </div>

        <footer v-if="watchOnly && config.theme.footer_height" :class="['h-' + config.theme.footer_height + ' top-' + config.theme.footer_border]"
                :style="{borderTopWidth: config.theme.footer_border_width + 'px'}"
        >

            <div v-if="config.theme.footer_column_1 || config.theme.footer_column_2">
                <div class="footer-text-inner">
                    <div v-if="config.theme.footer_column_1" class="br-receiver">
                        {{ config.theme.footer_column_1 }}
                    </div>
                    <div v-if="config.theme.footer_column_2" class="br-receiver">
                        {{ config.theme.footer_column_2 }}
                    </div>
                </div>
            </div>

            <div class="video-footer-wrapper"
                 v-if="config.video_footer_url && config.theme.footer_video_style !== 'hide'"
                 :class="config.theme.footer_video_style"
            >

                <video autoplay loop muted v-if="isVideo(config.video_footer_url)">
                    <source :src="config.video_footer_url" type="video/mp4" />Your browser does not support the video tag.
                </video>
                <img v-else :src="config.video_footer_url" />
            </div>

            <div class="background-image-filler" v-if="config.background_footer_url && (!config.video_footer_url || config.theme.footer_video_style === 'hide')"
                :style="[{ backgroundImage: 'url(' + config.background_footer_url + ')'},
                 {backgroundPosition: config.theme.footer_image_position_x + ' ' + config.theme.footer_image_position_y},
                 {backgroundSize: config.theme.footer_image_size}]"
            >
            </div>

            <div class="footer-language-switcher" v-if="config.theme.language_switcher_mode === 'bottom'">
                <language-selection />
            </div>
        </footer>

        <modal-wallet-add-credit ref="modalwalletaddcredit" @toggleScan="this.scanToggle()" @loading="loading = $event" :topup="true"></modal-wallet-add-credit>
    </div>
</template>

<script>
// 1. scrijf een functie die de modalwalletaddcredit opent.
// 2. verwacht een emit in de itemtile om de modal te opnen.
//    emit roept de functie open().
// 3. emit wordt aangemaakt in itemhelper



// libs
import db from "../lib/db";
import * as _state from "../lib/state";
import * as _log from '../lib/log';

import {
    Splide,
    SplideSlide,
} from '@splidejs/vue-splide';

// components
import LanguageSelection from "../components/LanguageSelection";
import Basket from '../components/Basket.vue';
import ModalWalletAddCredit from "../components/ModalWalletAddCredit.vue";

// import Idle from '../components/Idle.vue';
import InlineSvg from 'vue-inline-svg';
// import Item from '../components/Item.vue';
import ItemTile from '../components/ItemTile.vue';
import Information from '../components/Information.vue';
import Navigation from '../components/Navigation.vue';
import Scanner from '../components/Scanner.vue';


// set the logger

const logger = _log.get('MenuSelection');

export default {
    name: 'menu.selection',
    emits: ['toggleScan'],
    components: {
        Basket,
        // Idle,
        // Item,
        ItemTile,
        InlineSvg,
        Information,
        Navigation,
        Splide,
        SplideSlide,
        Scanner,
        ModalWalletAddCredit,
        LanguageSelection,
    },
    data () {
        return {
            disableScan: false,
            loading: false,
            selected_category_name: '',
            selected_category_description_long: '',
            category_selected: false,
            categories: [],
            items: [],
            payment_pin: null,
            hide_categories: false,
            optionsHorizontal: {
                rewind: true,
                gap: '2rem',
                pagination: false,
                perPage: 4,
                perMove: 1,
                wheel: true,
            },
            optionsVerticalSmall: {
                direction: 'ttb',
                perPage: 3,
                heightRatio: 3,
                gap: '1rem',
                pagination: false,
                rewind: true,
            },
            optionsVerticalLarge: {
                direction: 'ttb',
                perPage: 4,
                heightRatio: 4,
                gap: '1rem',
                pagination: false,
                rewind: true,
            },
            optionsPortrait: {
                heightRatio: 0.1,
                rewind: true,
                gap: '2rem',
                pagination: false,
                perPage: 4,
                perMove: 1,
                wheel: true,
            },
            watchOnly: true,
            largeMode: true,
        };
    },
    async mounted () {
        await db.initialize();
        // logger.log('config', this.config)()
        this.categories = await db.get('categories');

        this.category_selected = this.categories.length > 0 ? this.categories[0].id : false;

        // select first category
        if (this.categories.length > 0) {
            // todo: change
            this.categorySelect(this.categories[0]);
            // this.categorySelect(this.categories[3]);
        }

        if (this.config.theme.user_mode !== 'watch-only') {
            this.watchOnly = false;
        }

        if (this.config.theme.show_categories === false && this.categories.length === 1) {
            this.hide_categories = true;
        }

        _state.setField('status/getStatus', 'status/setStatus', 'item_adding', false);
    },
    methods: {
        categorySelect (category) {
            this.category_selected = category.id;

            this.selected_category_name = category.attributes.name_translations;
            this.selected_category_description_long = category.attributes.description_long_translations;
            this.items = category.relationships.items.data;
        },

        isVideo(src) {
            return !src.includes('.gif');
        },

        // use now for adding class is empty category
        isChildCategoryClass(category) {
            let id = category.id;
            let products = category.relationships.items.data[0];

            if (products) {

                return 'non-empty-category';
                // category.relationships.items.data.forEach(function(elem) {
                //     let present = [];
                //     let child_ids = elem.attributes.category_ids;
                //
                //     child_ids.forEach(function(e) {
                //         present.push(e);
                //     });
                //
                    // elem.attributes.items
                    //
                    // console.log(present);
                    // console.log(present.includes(true) === false);

                    // if (present.includes(false) === false) {
                    //     return 'is-child-category';
                    // }
                // })
            }


            return 'empty-category';
        },

        setItem (default_item) {

            let item = default_item;

            if (default_item.attributes.dailyitems) {

                let menu_day = moment().format('dddd').toLowerCase();

                if (default_item.attributes.dailyitems[menu_day]) {
                    item = default_item.attributes.dailyitems[menu_day];
                }
            }
            return item;
        },

        scanToggle() {
            console.log('scanToggle', this.disableScan);
            this.disableScan = !this.disableScan;
        },

        openWalletAddCreditModal(item) {
            if (item) {
                this.$refs.modalwalletaddcredit.open('topUp', item);
            }
        }
    },
    computed: {
        // todo: from config
        sliderVersion () {
            if (this.config.theme.device === 'landscape-1024x768') {
                return this.optionsVerticalSmall;
            }
            else if (this.config.theme.device === 'landscape-1280x800') {
                return this.optionsVerticalLarge;
            }
            else if (this.config.theme.device === 'portrait-800x1280') {
                return this.optionsPortrait;
            }
            return this.optionsHorizontal;
        },
        // todo: remove transaction
        transaction () {
            return _state.get('transaction/getTransaction');
        },

        config () {
            return _state.get('config/getConfig');
        },

        language () {
            return this.config.language;
        },

        backbutton () {
            if (this.config.theme.flow == 'barcodescanner') {
                return 'barcode.scanner';
            }
            return false;
        },

        // // layout options
        // columns () {
        //     return 'columns-' + this.config.theme.layout_columns;
        // },

        // tile () {
        //     return this.config.theme.layout_tile_layout;
        // },
    },
};
</script>
