export default {
    namespaced: true,
    state: {
        payment: JSON.stringify({})
    },

    getters: {
        getPayment: state => state.payment,
    },

    mutations: {
        PAYMENT(state, payment) {
            state.payment = payment;
        },
    },

    actions: {
        setPayment({ commit }, payment) {
            commit('PAYMENT', payment);
        },
    }
}
