<template>
     <modal-manager-login ref="login" @loggedIn="open()" />

    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div> <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                    <button @click="close()" type="button" class="close-button">
                        <span class="sr-only">{{ $t('translations.global.close') }}</span>
                        <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div class="mt-3">
                        <h3 class="mb-5 text-lg leading-6 font-medium" id="modal-title">
                            {{ $t('translations.modal.manager.title') }}
                        </h3>
                        <router-link :to="{name:'boot', params: {browserreload: true}}" class="mb-5 btn btn-secondary inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:text-sm">Reboot</router-link>

                        <button v-if="config.elockers_config_required == true" type="button" @click="$refs.lockerconfiguration.open()" class="mb-5 btn btn-secondary inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:text-sm">
                            {{ $t('translations.modal.manager.button-lockerconfiguration') }}
                        </button>

                        <button type="button" @click="printerTest()" class="mb-5 btn btn-secondary inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:text-sm">
                            {{ $t('translations.modal.manager.button-testprinter') }}
                        </button>

                        <button type="button" @click="$refs.printerreset.open()" class="mb-5 btn btn-secondary inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:text-sm">
                            {{ $t('translations.modal.manager.button-printerreset') }}
                        </button>

                        <button type="button" @click="$refs.configuration.open()" class="mb-5 btn btn-secondary inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:text-sm">
                            {{ $t('translations.modal.manager.button-config') }}
                        </button>

                        <!-- <button type="button" @click="$refs.areaselection.open()" class="mb-5 btn btn-secondary inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:text-sm">
                            {{ $t('translations.modal.manager.button-areaselection') }}
                        </button> -->

                        <!-- <button type="button" @click="$refs.eventselection.open()" class="mb-5 btn btn-secondary inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:text-sm">
                            {{ $t('translations.modal.manager.button-eventselection') }}
                        </button> -->

                        <button type="button" @click="$refs.transactions.open()" class="mb-5 btn btn-secondary inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:text-sm">
                            {{ $t('translations.modal.manager.button-transactions') }}
                        </button>

                        <button type="button" @click="$refs.itemtoggle.open()" class="mb-5 btn btn-secondary inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:text-sm">
                            {{ $t('translations.modal.manager.button-itemtoggle') }}
                        </button>

                        <button type="button" @click="$refs.logout.open()" class="inline-flex btn btn-primary justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:text-sm">
                            {{ $t('translations.modal.manager.button-logout') }}
                        </button>

                        <div class="text-xs flex justify-center mt-5">
                            <version />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal-manager-configuration ref="configuration"></modal-manager-configuration>
        <modal-manager-locker-configuration ref="lockerconfiguration"></modal-manager-locker-configuration>
        <modal-manager-areaselection ref="areaselection"></modal-manager-areaselection>
        <modal-manager-eventselection ref="eventselection"></modal-manager-eventselection>
        <modal-manager-logout ref="logout"></modal-manager-logout>
        <modal-manager-item-toggle ref="itemtoggle"></modal-manager-item-toggle>
        <modal-manager-printer-reset ref="printerreset"></modal-manager-printer-reset>
        <modal-manager-transactions ref="transactions"></modal-manager-transactions>
    </div>

</template>

<script>

// libs
import * as _log from '../lib/log';
import * as _state from "../lib/state";
import * as _printer from '../lib/printer';
import * as _receipt_test from '../lib/receipts/test';

// components
import ModalManagerConfiguration from './ModalManagerConfiguration.vue';
import ModalManagerLockerConfiguration from './ModalManagerLockerConfiguration.vue';
import ModalManagerAreaselection from './ModalManagerAreaselection.vue';
import ModalManagerEventselection from './ModalManagerEventselection.vue';
import ModalManagerLogin from './ModalManagerLogin.vue';
import ModalManagerLogout from './ModalManagerLogout.vue';
import ModalManagerItemToggle from './ModalManagerItemToggle.vue';
import ModalManagerTransactions from './ModalManagerTransactions.vue';
import ModalManagerPrinterReset from './ModalManagerPrinterReset.vue';
import Version from './Version.vue';

// set the logger
const logger = _log.get('ModalManager');

export default {
    name: 'modal.manager',
    components: {
        ModalManagerConfiguration,
        ModalManagerLockerConfiguration,
        ModalManagerAreaselection,
        ModalManagerEventselection,
        ModalManagerLogin,
        ModalManagerLogout,
        ModalManagerItemToggle,
        ModalManagerTransactions,
        ModalManagerPrinterReset,
        Version,
    },
    data () {
        return {
            active: false,
        };
    },
    methods: {
        open() {
            this.active = true;
        },

        close() {
            this.active = false;
        },

        login() {
            this.$refs.login.open();
        },

        printerTest() {
            _printer.print(_receipt_test.getRequest());
        },
    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
    },
};
</script>
