<template>

<!--    todo: don't show just eventname when landscape-1024x768 -->
    <div class="flex items-center information-wrapper" v-if="ticketCounter > 0 || config.integration_elockers_event_name"
        :class="{'justify-between': ticketCounter > 0, 'justify-end thin-border': ticketCounter == 0, 'only-event' : ticketCounter == 0 && config.integration_elockers_event_name}">
        <div v-if="ticketCounter > 0">
            <div class="text-2xl opacity-70">{{ $t('translations.checkout.order-expires-in') }}:</div>
            <countdown type="elockers" />
        </div>
        <div class="ml-2 text-2xl text-right event-name" v-if="config.integration_elockers_event_name">
            <div class="opacity-70">
                {{ $t('translations.information.event') }}
            </div>
            <div>
                {{ config.integration_elockers_event_name }} - {{ config.integration_elockers_event_date }}
                <div class="text-xs">{{ config.integration_elockers_location_name }}: {{ config.integration_elockers_area_name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
// libs
import moment from "moment";
import * as _elockers from "../lib/elockers";
import * as _state from "../lib/state";

// components
import Countdown from "./Countdown";

// set the logger

export default {
    name: 'information',
    components: {
        Countdown,
    },
    computed: {

        config () {
            return _state.get('config/getConfig');
        },

        ticketCounter () {
            return _elockers.ticketCounter();
        },

        event_date() {
            if (this.config.event_date) {
                return moment(this.config.event_date, 'YYYY-MM-DD hh:mm:ss').format('DD-MM');
            }
            return '';
        }
    },
};
</script>
