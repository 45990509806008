<template>
     <div :class="{'image-exists': this.img, 'img-loaded': imgLoaded}" class="">
        <svg class="loading" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="26.349px" height="26.35px" viewBox="0 0 26.349 26.35"
            style="enable-background:new 0 0 26.349 26.35;"
            xml:space="preserve">
            <g>
                <g>
                    <circle cx="13.792" cy="3.082" r="3.082" />
                    <circle cx="13.792" cy="24.501" r="1.849" />
                    <circle cx="6.219" cy="6.218" r="2.774" />
                    <circle cx="21.365" cy="21.363" r="1.541" />
                    <circle cx="3.082" cy="13.792" r="2.465" />
                    <circle cx="24.501" cy="13.791" r="1.232" />
                    <path d="M4.694,19.84c-0.843,0.843-0.843,2.207,0,3.05c0.842,0.843,2.208,0.843,3.05,0c0.843-0.843,0.843-2.207,0-3.05
                        C6.902,18.996,5.537,18.988,4.694,19.84z" />
                    <circle cx="21.364" cy="6.218" r="0.924" />
                </g>
            </g>
        </svg>
        <img @load="loadImage" :src="this.img">
    </div>
</template>

<script>

export default {
    name: 'ItemImage',
    props: ['img'],
    data () {
        return {
            imgLoaded: false,
        };
    },

    methods: {
        loadImage() {
           this.imgLoaded = true;
        },
    },
};
</script>
