export const getRequest = () => {

    var builder = new StarWebPrintBuilder();

    // Create Request
    var request = '';
    request += builder.createInitializationElement();
    request += builder.createTextElement({ characterspace: 2 });
    request += builder.createAlignmentElement({ position: 'center' });
    request += builder.createTextElement({ emphasis: true });
    request += builder.createTextElement({ data: 'TEST PRINTER\n' });
    request += builder.createCutPaperElement({
        feed: true,
        type: 'full',
    });

    return request;
}
