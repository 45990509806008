<template>
    <div v-if="!this.watch" class="basket-inner basket-styles br-receiver"
        :class="{'has-products-in-cart': transaction.lines.length > 0}"
    >
        <div class="order-overview flex-grow overflow-y-auto">
            <h2 class="text-3xl font-bold text-left mb-6">{{ $t('translations.basket.title') }}</h2>
            <div v-if="transaction.lines" class="overflow-auto basket-products-wrapper">
                <!-- <div class="grid grid-cols-12 py-5 pr-3"  :class="{'bg-gray-100': ((index % 2) !== 1)}" v-for="(line, index) in transaction.lines" v-bind:key="index"> -->
                <div class="transaction-item" v-for="(line, index) in transaction.lines" v-bind:key="index"
                     :class="{'item-child': line.parent_id}"
                >
                    <div class="amount" v-if="line.quantity > 0">
                        <div class="col-span-1 flex justify-end" v-if="line.editable">
                            <button type="button" @click="itemQuantitySubtract(line)" v-if="line.editable"
                                    class="text-lg leading-5 rounded-sm border p-1">
                                <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z" />
                                </svg>
                            </button>
                        </div>
                        <div class="col-span-1 flex justify-center digit">
                            <div class="text-lg leading-5">{{ line.quantity }}</div>
                        </div>
                        <div class="col-span-1 flex justify-start" v-if="line.editable">
                            <button type="button" v-if="line.editable" @click="itemQuantityAdd(line)"
                                    class="cursor-pointer text-lg leading-5 rounded-sm border p-1">
                                <svg v-if="item_loading !== line.item_id" width="20" height="20" viewBox="0 0 20 20"
                                     fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z"
                                          fill="currentColor" />
                                </svg>
                                <svg v-else class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                     fill="none"
                                     viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <!-- <div class="col-span-12">{{ line.tickets_elockers.length }}</div> -->

                    <!-- <div class="col-span-2 flex items-center">
                        <div class="flex-shrink-0 h-12 rounded-sm">
                            <img v-if="line.thumbnail" class="bg-white m-1 p-1 border max-h-10" :src="line.thumbnail">
                        </div>
                    </div> -->

                    <div class="info">
                        <div class="name ">
                            <div class="text-lg leading-5" :class="{'italic': (line.addon || !line.editable)}">
                                <span v-if="line.description_translations">
                                    {{
                                        line.description_translations[language] ? line.description_translations[language] : line.description
                                    }}
                                </span>
                                <span v-else>
                                    {{ line.description }}
                                </span>
                            </div>
                            <div class="italic text-s mt-3"
                                 v-for="(kitchen_grocery, grocery_index) in line.kitchen_groceries"
                                 v-bind:key="grocery_index">
                                {{ kitchen_grocery.description }}: {{ kitchen_grocery.value }}
                            </div>
                            <div class="italic text-s mt-3" v-if="line.variant_label">
                                {{ line.variant_label }}
                            </div>

                            <span class="inline-amount">
                                <span class="times">+</span> {{ line.quantity }}
                            </span>
                        </div>
                        <div class="price font-bold text-lg">
                            <!-- <div v-if="line.total_original != line.total" class="line-through mr-2 text-xs">€ {{ line.total_original }}</div> -->
                            {{ $n(line.total, currency) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="payment-overview">
            <div>
                <p class="text-lg mb-2">{{ $t('translations.basket.total') }}: </p>
                <h3 class="text-2xl">
                    {{ $n(transaction.total, currency) }}
                </h3>
            </div>
            <!--            Number of items: {{ transaction.number_of_items }}<br>-->
            <!-- Total: {{ transaction.total }}<br> -->

            <div>
                <div v-if="payment_pin && config.theme.direct_pin_payment === true"
                     class="mt-4 place-order">
                    <button
                        @click="gotoPayment(payment_pin.id, payment_pin.attributes.name, payment_pin.attributes.psp_mode)"
                        :disabled="transaction.number_of_items === 0" type="button" class="btn btn-primary ">
                        {{ $t('translations.basket.button-checkout') }}

                        <div class="glow" v-if="config.theme.user_mode === 'snoeien'"></div>
                    </button>
                </div>

                <div v-else
                     class="mt-4 place-order" >
                    <button @click="checkout()" :disabled="transaction.number_of_items === 0" type="button"
                            class="btn btn-primary ">
                        {{ $t('translations.basket.button-checkout') }}

                        <div class="glow" v-if="config.theme.user_mode === 'snoeien'"></div>
                    </button>
                </div>

                <div class="mt-4 cancel-order">
                    <button @click="basketClear()" type="button" class="btn btn-tertiary">
                        {{ $t('translations.basket.button-cancel-order') }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- modals -->
    <modal-cancel ref="modalcancel" />
    <auto-cancel @reset="basketClear()" />

</template>

<script>
import db from "../lib/db";

// libs
import * as _log from '../lib/log';
import * as _state from "../lib/state";
import * as _transaction from '../lib/transaction';

//mixins
import PaymentHelper from '../mixins/payment.helper.js';

// components
import ModalCancel from './ModalCancel.vue';
import AutoCancel from './AutoCancel.vue';

// set the logger
const logger = _log.get('Basket');

export default {
    name: 'basket',
    props: ['watch'],
    mixins: [PaymentHelper],
    components: {
        ModalCancel,
        AutoCancel,
    },
    data () {
        return {
            payment_pin: null,
            click_trigger: 0,
        };
    },

    async mounted () {
        this.setPinPayment();
    },

    methods: {
        basketClear () {
            if (!this.watch) {
                try {
                    this.$refs.modalcancel.open();
                } catch (error) {
                    //console.log(error);
                    console.log("modalcancel not found");
                }
            } else {
                this.$router.replace({ name: 'landing' });
            }
        },

        async setPinPayment () {
            if (this.config.theme.direct_pin_payment === false) {
                return;
            }

            await db.initialize();
            let paymenttypes = await db.get('paymenttypes');

            this.payment_pin = paymenttypes.find(payementtype => payementtype.attributes.preferred_payment === true
                && (['adyen_pos', 'ccv_pin', 'adyen']).includes(payementtype.attributes.psp_type));
        },

        checkout () {
            this.$router.replace({ name: 'checkout' });
        },

        itemQuantityAdd (line) {
            _transaction.addQuantity(line);
        },

        itemQuantitySubtract (line) {
            _transaction.substractQuantity(line);
        },
    },
    computed: {
        transaction () {
            return _state.get('transaction/getTransaction');
        },

        item_loading () {
            let status = _state.get('status/getStatus');

            return status.item_loading;
        },

        config () {
            return _state.get('config/getConfig');
        },

        currency(){
            return this.config.currency;
        },

        language () {
            return this.config.language;
        },
    },
};
</script>
