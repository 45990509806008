<template>
    <div class="checkout-wrapper main-wrapper">
        <navigation :backbutton="!locked ? 'menu.selection' : false" />

        <div class="padding-wrapper mb-4 flex justify-between">
            <h1>{{ $t('translations.checkout.title') }}</h1>
        </div>

        <div v-if="transaction.lines.length > 0" class="flex-grow flex flex-col padding-wrapper overflow-auto">

            <div class="checkout-lines overflow-auto">
                <div class="checkout-item grid grid-cols-12 items-center" v-for="(line, index) in transaction.lines"
                     v-bind:key="index"
                     :class="{'item-child': line.parent_id}"
                >
                    <div class="col-span-2 product-image">
                            <div v-if="line.thumbnail" class="flex justify-center br-receiver">
                                <img class="" :src="line.thumbnail">
                            </div>

                    </div>

                    <div class="col-span-4 flex items-start flex-col pl-3">

                        <div class="font-bold leading-5 truncate"
                             :class="{'ml-5 italic': (line.addon || !line.editable)}">
                            <span v-if="line.description_translations">
                                {{
                                    line.description_translations[language] ? line.description_translations[language] : line.description
                                }}
                            </span>
                            <span v-else>
                                {{ line.description }}
                            </span>
                        </div>
                        <div class="ml-3 italic mt-3" v-for="(kitchen_grocery, grocery_index) in line.kitchen_groceries"
                             v-bind:key="grocery_index">
                            {{ kitchen_grocery.description }}: {{ kitchen_grocery.value }}
                        </div>
                        <div class="text-s ml-3 mt-3" v-if="line.variant_label">
                            {{ line.variant_label }}
                        </div>
                    </div>

                    <div class="col-span-3 flex items-center grid grid-cols-3 amount">
                        <div class="col-span-1 flex justify-end">
                            <button type="button" v-if="line.editable && !basket_locked && line.quantity > 0"
                                    @click="itemQuantitySubtract(line)" class="text-lg leading-5 rounded-sm border p-1">
                                <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z" />
                                </svg>
                            </button>
                        </div>
                        <div class="col-span-1 flex justify-center digit">
                            <div class="leading-5">{{ line.quantity }}</div>
                        </div>
                        <div class="col-span-1 flex justify-start">
                            <button type="button" v-if="line.editable && !basket_locked" @click="itemQuantityAdd(line)"
                                    class="cursor-pointer text-lg leading-5 rounded-sm border p-1">
                                <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <!-- <div class="col-span-12">{{ line.tickets_elockers.length }}</div> -->

                    <!-- <div class="col-span-2 flex items-center">
                        <div class="flex-shrink-0 h-12 rounded-sm">
                            <img v-if="line.thumbnail" class="bg-white m-1 p-1 border max-h-10" :src="line.thumbnail">
                        </div>
                    </div> -->
                    <div :class="{'italic': !line.editable}" class="col-span-3 flex items-center justify-end font-bold">
                        <div class="flex justify-end items-center">
                            <div class="mr-5 line-through font-normal" v-if="line.discount_amount > 0">
                                {{ $n(line.total_without_discount, currency) }}
                            </div>
                            <div> {{ $n(line.total, currency) }}</div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="payment-area flex justify-end">
                <!--                <div class="mt-10 grid grid-cols-12">-->
                <!--                    <div class="col-span-2 col-start-9 font-bold">Payments:</div>-->
                <!--                </div>-->
                <div class="">
                    <div class="mb-10" v-if="transaction.coupons.length > 0">

                        <div class="mt-5 flex justify-between">
                            <div class="font-bold ">{{ $t('translations.checkout.coupons') }}</div>
                        </div>

                        <div class="mb-2 flex justify-between items-center" v-for="(coupon, index) in transaction.coupons" v-bind:key="index">
                            <div class="">{{ coupon.description }}
                                <span class="italic" v-if="coupon.type == 'discount_percentage_fixed'">
                                    (-{{ coupon.value * 100 }}%)
                                </span>
                            </div>
                            <button type="button" v-if="!basket_locked"
                                    @click="couponDelete(coupon)"
                                    class="text-lg leading-5 rounded-sm border p-1 delete-coupon">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                     stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div v-if="transaction.payments.length > 0">
                        <div class="flex justify-between font-bold">
                            <div class="">{{ $t('translations.checkout.subtotal') }}</div>
                            <div class="text-right">{{ $n(transaction.total, currency) }}</div>
                        </div>

                        <div class="mt-5 flex justify-between">
                            <div class="font-bold">{{ $t('translations.checkout.paid') }}</div>
                        </div>

                        <div class="mb-10 flex justify-between" v-for="(payment, index) in transaction.payments"
                             v-bind:key="index">
                            <div class="">{{ payment.name }}</div>
                            <div class="text-right">{{ $n(payment.amount, currency) }}</div>
                        </div>
                    </div>

                    <h1 class="flex justify-between mt-2">
                        <div class=" font-bold mr-4">{{ $t('translations.checkout.total') }}</div>
                        <div class="text-right">{{ $n(transaction.total_unpaid, currency) }}</div>
                    </h1>

                    <!-- <div v-if="transaction.total_paid" class="flex justify-between">
                        <div class="">{{ $t('translations.checkout.paid') }}</div>
                        <div class="text-right">{{ $n(transaction.total_paid, currency) }}</div>
                    </div> -->

                    <!-- <div class="flex justify-between">
                        <div class="">Number of items</div>
                        <div class="text-right">{{ transaction.number_of_items }}</div>
                    </div> -->
                </div>
            </div>
        </div>

        <!-- no lines -->
        <div v-else class="padding-wrapper large-buttons">
            <div class="mb-4 text-2xl opacity-70">{{ $t('translations.checkout.basket-empty') }}</div>

            <router-link class="btn btn-primary" v-if="!basket_locked" :to="{name: backbutton}">
                {{ $t('translations.global.go-back') }}
            </router-link>
        </div>

        <div v-if="transaction.lines.length > 0" class="mt-20 padding-wrapper large-buttons">
            <div class="flex justify-between mb-5 payment-buttons-splitter">
                <div class="flex justify-start">

<!--                    add extra wrapper, btn styling overrides hidden styling-->
                    <div :class="{'hidden': config.theme.allow_coupons === false}">
                       <button v-if="!basket_locked" @click="$refs.modalcoupon.open()"
                            class="mr-5 btn btn-secondary">
                            {{ $t('translations.checkout.button-coupon') }}
                       </button>
                    </div>

                    <button v-for="(paymenttype, index) in paymenttypes_voucher" :key="index"
                            @click="$refs.modalvoucher.open(paymenttype.id)"
                            class="mr-5 btn btn-secondary">
                        {{ paymenttype.attributes.name }}
                    </button>

                    <button v-for="(paymenttype, index) in paymenttypes_wallet" :key="index" @click="$refs.modalwallet.open(paymenttype.id)" class="mr-5 btn btn-secondary">
                        {{ paymenttype.attributes.name }}
                    </button>

                    <button v-if="transaction.payments.length > 0" @click="cancelPayments()"
                            class="btn btn-secondary">
                        {{ $t('translations.checkout.button-payments-cancel') }}
                    </button>
                </div>
                <div>
                    <button v-for="(paymenttype, index) in paymenttypes_default" :key="index"
                            @click="gotoPayment(paymenttype.id, paymenttype.attributes.name, paymenttype.attributes.psp_mode)"
                            :disabled="transaction.number_of_items === 0" type="button"
                            class="mr-5 btn btn-primary">
                        {{ paymenttype.attributes.name }}
                    </button>
                </div>
            </div>
        </div>


        <information />

        <!-- check for activity -->
        <idle />

        <!-- modals -->
        <modal-coupon ref="modalcoupon"></modal-coupon>
        <modal-voucher ref="modalvoucher" :hidebuttons="false" @paid="paidByVoucher()" v-if="paymenttypes_voucher.length > 0"></modal-voucher>
        <modal-wallet ref="modalwallet" @paid="paidByVoucher()" v-if="paymenttypes_wallet.length > 0"></modal-wallet>
    </div>
</template>

<script>
// libs
import db from "../lib/db";
import * as _transaction from '../lib/transaction';
import * as _payment from '../lib/payment';
import * as _state from "../lib/state";
import * as _log from '../lib/log';

//mixins
import VoucherHelper from '../mixins/voucher.helper.js';
import PaymentHelper from '../mixins/payment.helper.js';

// components
import Idle from '../components/Idle.vue';
import ModalCoupon from '../components/ModalCoupon.vue';
import ModalVoucher from '../components/ModalVoucher.vue';
import ModalWallet from '../components/ModalWallet.vue';
import Navigation from '../components/Navigation.vue';
import Information from "../components/Information";


// set the logger
const logger = _log.get('Checkout');

export default {
    name: 'checkout',
    mixins: [VoucherHelper, PaymentHelper],
    components: {
        Idle,
        ModalCoupon,
        ModalVoucher,
        ModalWallet,
        Navigation,
        Information,
    },
    data () {
        return {
            busy: false,
            loading: false, // remove?
            paymenttypes_default: [],
            paymenttypes_voucher: [],
            paymenttypes_wallet: [],
        };
    },
    async mounted () {

        await db.initialize();

        // set the paymenttypes (only preferred)
        let paymenttypes = await db.get('paymenttypes');

        this.setPaymenttypesDefault(paymenttypes);
        this.setPaymenttypesVoucher(paymenttypes);
        this.setPaymenttypesWallet(paymenttypes);

        if (this.config.theme.device === 'S1U2') {
            this.$router.replace({ name: 'landing' });
        }
    },
    methods: {
        async paidByVoucher () {

            let response = await _transaction.send();

            if (response === true) {
                _payment.setStatus('paid');
                _payment.setId('voucherpayment');

                this.$router.push({ name: 'payment' });
            } else {
                // todo notification
                console.log('ERROR');
            }
        },

        // basket
        // todo: basket lock
        itemQuantityAdd (line) {
            if (this.locked == true) {
                return;
            }
            _transaction.addQuantity(line);
        },

        itemQuantitySubtract (line) {
            if (this.locked == true) {
                return;
            }
            _transaction.substractQuantity(line);
        },

        //  coupon
        couponDelete (coupon) {
            if (this.locked == true) {
                return;
            }
            _transaction.deleteCoupon(coupon);
        },

        // transaction
        cancelPayments () {
            _transaction.cancel();
        },

        // paymenttypes
        setPaymenttypesDefault (paymenttypes) {
            this.paymenttypes_default = paymenttypes.filter(paymenttype => {
                return paymenttype.attributes.voucher_payment === false && paymenttype.attributes.wallet_payment === false;
            });
        },


        setPaymenttypesWallet (paymenttypes) {
            this.paymenttypes_wallet = paymenttypes.filter(paymenttype => {
                return paymenttype.attributes.wallet_payment === true;
            });
        },
    },
    computed: {

        basket_locked () {
            let status = _state.get('status/getStatus');

            return status.basket_locked;
        },

        config () {
            return _state.get('config/getConfig');
        },

        currency(){
            return this.config.currency;
        },

        transaction () {
            return _state.get('transaction/getTransaction');
        },

        language () {
            let config = _state.get('config/getConfig');

            return config.language;
        },


        backbutton () {
            if (this.config.theme.flow == 'barcodescanner') {
                return 'barcode.scanner';
            }
            return 'menu.selection';
        },
    },
};
</script>
