<template>
	<div :class="
        config.theme.button_radius + ' ' +
        config.theme.card_radius
        ">
		<div class="wrapper body-styles body-wrapper"
            :class="
                'product-images-' + config.theme.product_images_style +
                ' category-images-' + config.theme.category_images_style +
                ' ' + config.theme.device +
                ' ' + config.theme.basket_placement
            "
        >
			<div class="container-fluid">
				<router-view />
			</div>
		</div>

        <!-- modals -->
        <modal-order-time-exceeded></modal-order-time-exceeded>
        <modal-printer-error></modal-printer-error>
        <modal-ticket-max-tickets></modal-ticket-max-tickets>
        <modal-ticket-unavailable-elockers></modal-ticket-unavailable-elockers>
        <modal-locker-config-missing></modal-locker-config-missing>
	</div>
</template>

<script>
// libs
import { connectWebsockets } from "../lib/websockets";
import * as _state from "../lib/state";

// components
import ModalOrderTimeExceeded from "../components/ModalOrderTimeExceeded.vue";
import ModalPrinterError from "../components/ModalPrinterError.vue";
import ModalTicketMaxTickets from "../components/ModalTicketMaxTickets.vue";
import ModalTicketUnavailableElockers from "../components/ModalTicketUnavailableElockers.vue";
import ModalLockerConfigMissing from '../components/ModalLockerConfigMissing.vue';

export default {
    name: 'home',
    components: {
        ModalOrderTimeExceeded,
        ModalPrinterError,
        ModalTicketUnavailableElockers,
        ModalTicketMaxTickets,
        ModalLockerConfigMissing,
    },
    mounted() {
        connectWebsockets();

        this.importCssTheme();

        // set the language from the config
        // this.$i18n.locale = this.config.language;
    },

    methods: {

        importCssTheme() {
            if (this.config.theme_url) {
                let style = document.createElement('link');
                style.type = "text/css";
                style.rel = "stylesheet";
                style.href = this.config.theme_url;
                document.head.appendChild(style);
            }
        },
    },

    computed: {
        config() {
            return _state.get('config/getConfig')
        },
    }
}
</script>
