<template>
    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto"
         aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal modal-full transform padding-wrapper">

                <div class="modal-inner">
                    <h1 class="" id="modal-title">
                        {{ $t('translations.modal.manager-printer-reset.title') }}
                    </h1>
                     <p>{{ $t('translations.modal.manager-printer-reset.description') }}</p>
                    <div class="button-flexer">
                        <button type="button" @click="reset()" class="inline-flex btn btn-primary justify-center w-full">
                            {{ $t('translations.modal.manager-printer-reset.button-confirm') }}
                        </button>
                        <button type="button" @click="close()" class="btn btn-secondary">
                            {{ $t('translations.modal.manager-printer-reset.button-cancel') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// libs
import * as _printer from '../lib/printer';

export default {
    name: 'modal.manager.printer.reset',
    data () {
        return {
            active: false,
            resetted: false,
        };
    },
    methods: {
        open () {
            this.resetted = false;
            this.active = true;
        },

        close () {
            this.resetted = false;
            this.active = false;
        },

        reset () {
            _printer.reset();

            this.resetted = true;

            this.active = false;
        },
    },
};
</script>
