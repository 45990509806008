<template>
    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div> <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                    <button @click="close()" type="button" class="close-button">
                        <span class="sr-only">{{ $t('translations.global.close') }}</span>
                        <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div class="mt-3 sm:mt-5">
                        <h3 class="mb-5 text-lg leading-6 font-medium" id="modal-title">
                            {{ $t('translations.modal.manager-logout.title') }}
                        </h3>
                        <p class="">{{ $t('translations.modal.manager-logout.description') }}</p>
                        <div class="mt-5 sm:mt-6">
                            <button type="button" @click="logout()" class="btn btn-primary w-full mb-5">
                                {{ $t('translations.modal.manager-logout.button-confirm') }}
                            </button>
                            <button type="button" @click="close()" class="btn btn-secondary w-full">
                                {{ $t('translations.modal.manager-logout.button-cancel') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// libs
import * as _state from "../lib/state";

export default {
    name: 'modal.manager.logout',
    data () {
        return {
            active: false,
        };
    },
    methods: {
        open() {
            this.active = true;
        },

        close() {
            this.active = false;
        },

        logout() {
            _state.wipeStates();

            localStorage.removeItem('ident');

            this.$router.replace({'name': 'login'});
        },
    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
    },
};
</script>
