<template>
    <div class="modal modal-full padding-wrapper transform payment-modal">
        <div class="modal-inner text-center">
            <div class="button-flexer">
                <button id="cancelbutton" class="cancel-payment btn btn-secondary" :disabled="is_canceling === true" @click="paymentCancel()">
                    {{$t('translations.payment.cancel')}}
                    <svg v-if="is_canceling === true" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </button>

                <button id="cancelbutton-order" class="cancel-payment btn btn-secondary" :disabled="is_canceling === true" @click="basketClear()">
                    {{$t('translations.basket.button-cancel-order')}}
                    <svg v-if="is_canceling === true" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </button>
            </div>
        </div>

        <modal-cancel ref="modalcancel" />
    </div>
</template>

<script>
import * as _payment from '../lib/payment';

// mixins
import PaymentHelper from '../mixins/payment.helper.js';
import ModalCancel from "./ModalCancel";

export default {
    name: 'payment.pending',
    mixins: [PaymentHelper],
    components: {
        ModalCancel
    },
    methods: {
        basketClear () {
            this.$refs.modalcancel.open();
        },
    }
}
</script>
