import * as _api from './api';
import * as _state from "../lib/state";
import * as _log from '../lib/log';

// set the logger
const logger = _log.get('PAYMENT');

const init = async (transaction_id, paymenttype_id, paymenttype_name, paymenttype_mode, amount) => {

    let payment = {
        transaction_id: transaction_id,
        paymenttype_id: paymenttype_id,
        paymenttype_name: paymenttype_name,
        paymenttype_mode: paymenttype_mode,
        amount: amount,
        status: paymenttype_mode === 'sync' ? 'pending' : 'open',
    };
    _state.set('payment/setPayment', payment);

    logger.log('initilized payment', payment)();

    // post the payment to api
    let response = await _api.post('payment', payment);

    // handle the response
    if (response && response.result === 'success') {
        // redirect to payment page
        if (response.action.result_code == 'redirect') {

            logger.log('redirect', response.action.redirect_url)();

            setId(response.payment_id);
            setStatus(response.action.result_code);
            window.location = response.action.redirect_url;

            return;
        }

        setId(response.payment_id);
        setStatus(response.action.result_code);

        return true;
    }
    // check status
    let status = await getStatus();

    if (status === 'open') {
        status = 'failed';
        cancel();
    }
    setStatus(status);

    return false;
}

const cancel = async () => {

    let payment = _state.get('payment/getPayment');

    logger.log('cancel payment', payment)();

    // post the payment to api
    let response = await _api.post('payment/cancel', {
        transaction_id: payment.transaction_id
    });
    // handle the response
    if (response && response.result === 'success') {
        // clear();
        // // redirect to payment page
        // if (response.action.result_code == 'redirect') {
        //     logger.log('redirect', response.action.redirect_url)();

        //     window.location = response.action.redirect_url;

        //     return;
        // }

        // setId(response.payment_id)
        // setStatus(response.action.result_code)

        return true;
    }

    return false;
}

const clear = () => {
    _state.set('payment/setPayment', {});

    logger.log('payment cleared')();
}


const getStatus = async () => {

    let payment = _state.get('payment/getPayment');

    // post the payment to api
    let response = await _api.post('payment/status', {
        transaction_id: payment.transaction_id,
        payment_id: payment.id,
    });

    // handle the response
    if (response && response.result === 'success') {
        logger.log('status of payment', response.status)();
        return response.status;
    }
    return false;
}

const setStatus = (status) => {
    let transaction = _state.get('transaction/getTransaction');
    let payment = _state.get('payment/getPayment');

    _state.setField('payment/getPayment', 'payment/setPayment', 'status', status);

    logger.log('payment status set', status)();

    if (status == 'paid') {
        _state.setField('status/getStatus', 'status/setStatus', 'basket_locked', false);
    }
    else if (status == 'failed' && payment.status == 'pending' && transaction.payments.filter(pt => pt.status == 'paid').length == 0) {
        _state.setField('status/getStatus', 'status/setStatus', 'basket_locked', false);
    }
    //
}

const setId = (id) => {
    // let payment = _state.get('payment/getPayment');

    // payment.id = id;

    // _state.set('payment/setPayment', payment);
    _state.setField('payment/getPayment', 'payment/setPayment', 'id', id);

    logger.log('payment id set', id)();
}

// const paymentStartPayment = (payment)


export {
    // paymentInit,
    init,
    cancel,
    clear,
    getStatus,
    setStatus,
    setId,
    // paymentItemAdd,
    // paymentItemQuantityAdd,
    // paymentItemQuantitySubtract,
    // paymentStatus,
    // paymentSend,
}
