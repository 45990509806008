<template>
    <div v-if="show_modal" class="fixed z-30 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block sm:p-0">
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal"
                 class="fixed align-bottom transform modal-large animated text-left br-receiver"
                 :class="config.theme.modal_position_size + ' modal-image-' + config.theme.modal_image_mode"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline" ref="itemmodal"
                 :style="[{ backgroundImage: 'url(' + config.background_modal_url + ')'},
                 {backgroundPosition: config.theme.modal_background_image_position_x + ' ' + config.theme.modal_background_image_position_y },
                 {backgroundSize: config.theme.modal_background_image_size},
                 ]"
            >

                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <a @click="closeModal('large'), this.$emit('toggleScan')"
                       class="rounded-sm  text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span class="sr-only">{{ $t('translations.global.close') }}</span>
                        <!-- Heroicon name: x -->
                        <svg class="h-16 w-16 cursor-pointer" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24"
                             stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </a>
                </div>


                <div class="modal-content px-4 pt-5 pb-4 sm:p-8 sm:pb-4">
<!--                    todo: fix boolean-->
                    <div class="inner" :class="{'image-in-column' : config.theme.modal_image === true}">
                        <div class="modal-image" v-if="config.theme.modal_image === true">
                            <item-image :img="item.attributes.thumbnail_url" />
                        </div>

                        <div>
                            <h1 class="popup-title" id="modal-headline" v-if="config.theme.modal_image_mode !== 'large'">
                                {{ item.attributes.webshop_description }}
                            </h1>

                            <div class="overflow-y-auto popup-content">
                                <!-- Details -->
                                <div v-if="step == 'details'">
                                    <div class="">
                                        <div v-if="item.attributes.thumbnail_url" class="flex justify-start image-column-controller">

                                            <div class="q popup-modal-image-description br-receiver">

                                                <item-image v-if="config.theme.user_mode === 'watch-only'" :img="item.attributes.image_url" />
                                                <item-image v-else :img="item.attributes.image_url" />

                                            </div>

                                            <div v-if="config.theme.modal_image_mode === 'large'" class="bottom-description-modal-image-large">
                                                <div class="barcode">
                                                    {{ item.attributes.barcode }}
                                                </div>

                                                <p v-html="item.attributes.kiosk_description_long_translations[language]">
                                                </p>
                                            </div>
                                            <p v-else v-html="item.attributes.kiosk_description_long_translations[language]">
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <!-- Variants -->
                                <div v-if="step == 'variants'" class="">
                                    <div class="radio-picker">
                                        <!-- <span class="relative z-0 inline-flex shadow-sm rounded-sm "> -->
                                        <div class="radio-item"
                                             v-for="(item_variant, variant_index) in item.attributes.variants"
                                             :key="variant_index">
                                            <!-- available -->
                                            <a v-if="item_variant.attributes.available_in_inventory"
                                               @click="variantSelect(item_variant)"
                                               class=""
                                               :class="item_variant.id == (variant ? variant.id : null) ? 'btn-primary' : 'btn-secondary'">
                                                <div>{{ item_variant.attributes.label }}</div>
                                                <div class="ml-2">
                                                    {{ item_variant.attributes.price }}
                                                </div>
                                            </a>

                                            <!-- soldout -->
                                            <a v-else
                                               class="opacity-25 line-through btn-secondary cursor-pointer w-full relative flex justify-between items-center">
                                                <div>{{ item_variant.attributes.label }}</div>
                                            </a>
                                        </div>
                                        <!-- </span> -->
                                    </div>
                                    <div class="text-xs text-red-600" v-if="errors.variants && !variant">
                                        {{ $t('translations.validation_messages.required') }}
                                    </div>
                                </div>

                                <!-- Addons -->
                                <div v-if="step == 'addons'">
                                    <div class="" v-for="(addon, addon_index) in addons" :key="addon_index">
                                        <div v-if="addon_index == step_addon">
                                            <div class="font-bold text-left popup-subtitle">
                                                <h2 class="">
                                                    {{ addon.description }}
                                                    <span v-if="addon.quantity_min > 0">*</span>
                                                </h2>
                                                <div>
                                                    <!-- <div>{{ option.description }} ({{ option.price }})</div> -->
                                                    <div class=""
                                                         :class="{'text-red-600': addon.error} ">
                                                            <span class="mr-2"
                                                                  v-if="addon.quantity_min || addon.quantity_max">
                                                                   {{ $t('translations.item.addon.select') }}
                                                            </span>
                                                        <span v-if="addon.quantity_min && addon.quantity_max">
                                                        {{ $t('translations.item.addon.minimum') }}
                                                        {{ addon.quantity_min }} &
                                                        {{ $t('translations.item.addon.maximum') }}
                                                        {{ addon.quantity_max }}
                                                    </span>
                                                        <span
                                                            v-else-if="addon.quantity_min">
                                                                {{ $t('translations.item.addon.minimum') }}
                                                                {{ addon.quantity_min }}
                                                    </span>
                                                        <span v-else-if="addon.quantity_max">
                                                        {{ $t('translations.item.addon.maximum') }}
                                                        {{ addon.quantity_max }}
                                                    </span>
                                                    </div>
                                                </div>
                                                <!-- <span class="text-xs text-red-600" v-if="hasError('addons', addon.description)">verplicht</span> -->
                                            </div>

                                            <!-- buttons -->
                                            <div v-if="addon.type == 'buttons'">
                                                <div class="add-on-options"
                                                     v-for="(option, option_index) in addon.options"
                                                     :key="option_index">
                                                    <div class="name">
                                                        <div>{{ option.description }} ({{
                                                                option.price
                                                            }})
                                                        </div>
                                                        <div class="text-red-600"
                                                             v-if="option.error">
                                                            <span
                                                                v-if="option.quantity_min && option.quantity_max">
                                                                {{ $t('translations.item.addon.minimum') }}
                                                                    {{
                                                                    option.quantity_min
                                                                }} & {{ $t('translations.item.addon.maximum') }} {{
                                                                    option.quantity_max
                                                                }}</span>
                                                            <span v-else-if="option.quantity_min">
                                                            {{ $t('translations.item.addon.minimum') }}
                                                            {{ option.quantity_min }}
                                                        </span>
                                                            <span v-else-if="option.quantity_max">
                                                            {{
                                                                    $t('translations.item.addon.maximum')
                                                                }} {{ option.quantity_max }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="amount">
                                                        <div class="">
                                                            <a @click="changeAddonQuantity(addon, option, -1)"
                                                               class="button block rounded-sm border border-gray-200 p-1">
                                                                <svg class="h-5 w-5" fill="currentColor"
                                                                     viewBox="0 0 20 20">
                                                                    <path fill-rule="evenodd"
                                                                          clip-rule="evenodd"
                                                                          d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                        <div class="digit">
                                                            {{ parseInt(option.quantity) }}
                                                        </div>
                                                        <div>
                                                            <a @click="changeAddonQuantity(addon, option, 1)"
                                                               class="button block rounded-sm border border-gray-200 p-1">
                                                                <svg class="h-5 w-5" fill="currentColor"
                                                                     viewBox="0 0 20 20">
                                                                    <path fill-rule="evenodd"
                                                                          clip-rule="evenodd"
                                                                          d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="price">
                                                        <div>{{
                                                                $n((option.quantity * option.price), 'currency')
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- tiles -->
                                            <div v-if="addon.type == 'tiles'" class="tile-view-addon">
                                                <div class="relative br-receiver"
                                                
                                                 v-for="(option, option_index) in addon.options"
                                                        :key="option_index">
                                                        
                                                        <a @click="changeAddonQuantity(addon, option, -1)"
                                                            v-if="option.quantity > 0"
                                                            class="button block rounded-sm border border-gray-200 p-1 minus">
                                                            <svg class="h-5 w-5" fill="currentColor"
                                                                viewBox="0 0 20 20">
                                                                <path fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z" />
                                                            </svg>
                                                        </a>

                                                        <div class="total-amount" v-if="option.quantity > 0">€
                                                            {{ parseFloat((option.quantity * option.price)).toFixed(2) }}
                                                        </div>

                                                        <a @click="changeAddonQuantity(addon, option, 1)"
                                                            class="button block rounded-sm border border-gray-200 p-1 plus">
                                                            <svg class="h-5 w-5" fill="currentColor"
                                                                viewBox="0 0 20 20">
                                                                <path fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                                            </svg>
                                                        </a>

                                                        <div class="addon-background br-receiver relative" 
                                                            :style="{ backgroundImage: 'url(' + option.thumbnail_url + ')'}">

                                                            <div class="option-inner">
                                                                <div class="amount">
                                                                    {{ parseInt(option.quantity) }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                         <div class=" name"
                                                            @click="changeAddonQuantity(addon, option, 1)">
                                                            <div class="flex justify-center mr-2 text-xl">
                                                                {{ option.description }}
                                                            </div>
                                                            <div class="">
                                                                {{ $n(parseFloat(option.price), 'currency')}}
                                                            </div>
                                                        </div>

                                                        <!-- <div class="option">
                                                            <div class="flex justify-between items-center w-full">
                                                                <div>
                                                                    <div class="text-xs text-red-600"
                                                                        v-if="option.error">
                                                                                <span
                                                                                    v-if="option.quantity_min && option.quantity_max">{{
                                                                                        $t('translations.item.addon.minimum')
                                                                                    }} {{
                                                                                        option.quantity_min
                                                                                    }} & {{
                                                                                        $t('translations.item.addon.maximum')
                                                                                    }} {{
                                                                                        option.quantity_max
                                                                                    }}</span>
                                                                        <span v-else-if="option.quantity_min">{{
                                                                                $t('translations.item.addon.minimum')
                                                                            }} {{
                                                                                option.quantity_min
                                                                            }}</span>
                                                                        <span v-else-if="option.quantity_max">{{
                                                                                $t('translations.item.addon.maximum')
                                                                            }} {{
                                                                                option.quantity_max
                                                                            }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                            </div>

                                            <!-- radio -->
                                            <div v-if="addon.type == 'radio'" class="radio-picker">
                                                <div class="" v-for="(option, option_index) in addon.options"
                                                     :key="option_index">
                                                    <label class="radio-item"
                                                           :class="{'active' :option.quantity > 0}"
                                                           :for="option.id">{{ option.description }}
                                                        ({{ $n(parseFloat(option.price), currency) }})
                                                    </label>

                                                    <input @change="addonRadioSelect(addon, option)"
                                                           type="radio" :id="option.id" :name="addon.id"
                                                           :value="option.id">
                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Kitchen Groceries -->
                                <div id="kitchengroceries" v-if="step == 'kitchen_groceries'">
                                    <!-- kitchen groceries -->
                                    <div class=""
                                         v-for="(kitchen_grocery, kitchen_grocery_index) in item.attributes.kitchen_groceries"
                                         :key="kitchen_grocery_index"
                                         :class="{'mt-8': kitchen_grocery_index > 0}"
                                    >
                                        <fieldset>
                                            <legend class="w-full font-bold flex justify-start items-center">
                                                <span class="capitalize mr-4">{{ kitchen_grocery.description }} *</span>
                                                <span class="text-xs text-red-600"
                                                      v-if="hasError('kitchen_groceries', kitchen_grocery.description)">{{
                                                        $t('translations.validation_messages.required')
                                                    }}</span>
                                            </legend>
                                            <ul class="space-y-1 radio-picker" role="radiogroup"
                                                aria-labelledby="radiogroup-label">
                                                <li v-for="(option, option_index) in kitchen_grocery.options.split(';')"
                                                    :key="option_index" tabindex="0" role="radio"
                                                    class="group radio-item relative rounded-sm shadow-sm cursor-pointer focus:outline-none"
                                                    :class="{'active': isKitchenGrocerySelected(kitchen_grocery.description, option)}">
                                                    <div @click="setKitchenGrocery(kitchen_grocery.description, option)"
                                                         class="option">
                                                        {{ option }}
                                                    </div>
                                                </li>
                                            </ul>
                                        </fieldset>
                                    </div>
                                </div>

                                <div class="modal-footer grid popup-button-area">
                                    <div class="flex">
                                    <span class="flex w-full rounded-sm  shadow-sm sm:w-auto">
                                        <a v-if="step == 'details' && !item.watchOnly" @click="nextStep('details')"
                                           class="btn btn-primary border py-6 px-6 inline-flex items-center justify-center w-full text-3xl sm:leading-5 mr-4 btn-confirm">
                                            {{ $t('translations.item.button-confirm') }}
                                        </a>
                                        <!-- <a v-if="step == 'variants'" @click="confirmVariant()" class="btn btn-primary inline-flex justify-center w-full text-3xl sm:leading-5">
                                            {{ $t('translations.item.button-confirm') }}
                                        </a> -->
                                        <a v-if="step == 'addons' && !item.watchOnly" @click="confirmAddons()"
                                           class="btn btn-primary border py-6 px-6 inline-flex items-center justify-center w-full text-3xl sm:leading-5 mr-4 btn-confirm">
                                            {{ $t('translations.item.button-confirm') }}
                                        </a>
                                        <a v-if="step == 'kitchen_groceries' && !item.watchOnly"
                                           @click="confirmKitchenGroceries()"
                                           class="btn btn-primary border py-6 px-6 inline-flex items-center justify-center w-full text-3xl sm:leading-5 mr-4 btn-confirm">
                                            {{ $t('translations.item.button-confirm') }}
                                        </a>
                                        <!-- <a @click="addItemToCart()" class="btn btn-primary py-6 px-6 inline-flex items-center justify-center w-full text-3xl sm:leading-5">
                                           Toevoegen
                                       </a> -->
                                    </span>

                                    <span class="mt-3 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:w-auto">
                                        <a @click="closeModal(), this.$emit('toggleScan')"

                                           class="btn btn-secondary border text-3xl px-5 inline-flex items-center justify-center w-full px-6 py-6">
                                            {{ $t('translations.item.button-close') }}
                                        </a>
                                    </span>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
body {
    position: fixed;
}
</style>

<script>
// mixins
import ItemHelper from '../mixins/item.helper.js';
import * as _state from "../lib/state";

// components
import ItemImage from '../components/ItemImage.vue';

export default {
    name: 'Item',
    emits: ['popup', 'openWalletAddCreditModal'],
    props: ['view', 'initialitem', 'initialquantity'],
    mixins: [ItemHelper],
    components: {
        // Numpad
        ItemImage,
    },

    computed: {
        numberInTransaction () {
            let self = this;
            let quantity = 0;
            this.transaction.lines.forEach(line => {
                if (self.initialitem.id == line.item_id) {
                    quantity += line.quantity;
                }
                return line.item_id == this.initialitem.id;
            });
            return quantity;
        },
        transaction () {
            return this.$store.getters['transaction/transaction'];
        },
        config () {
            return _state.get('config/getConfig');
        },

        currency(){
            return this.config.currency;
        },
    },
};
</script>
