<template>
    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
         aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div
                class="modal modal-full transform padding-wrapper">
                <div class="modal-inner">
                    <button @click="close()" type="button" class="close-button">
                        <span class="sr-only">{{ $t('translations.global.close') }}</span>
                        <svg class="h-14 w-14" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div class="mt-3  sm:mt-5">
                        <div class="mb-8">
                            <h1 class="" id="modal-title">
                                {{ $t('translations.modal.voucher-scan.title') }}
                            </h1>

                            <div v-if="hasFakeScan == true" class="my-5" @click="barcodeScanned('C0QY82SO5NVDGKRZ14M9')"><a class="underline">Do Fake Scan (C0QY82SO5NVDGKRZ14M9)</a></div>
                            <div v-if="hasFakeScan == true" class="my-5" @click="barcodeScanned('M6H8WOSL4AQ20Y1IJBEK')"><a class="underline">Do Fake Scan (M6H8WOSL4AQ20Y1IJBEK)</a></div>

                            <p v-if="!voucher_in_header">{{ $t('translations.modal.voucher-scan.description') }}
                                {{ $n(transaction.total_unpaid, currency) }}
                            </p>
                        </div>


                        <div class="bordered-message bg-green-100 text-center text-green-500"
                             v-if="voucher_valid === true">
                            <h2>{{ $t('translations.modal.voucher-scan.status.valid') }}</h2>
                            <p>{{ $t('translations.modal.voucher-scan.amount-available') }}
                                {{ $n(voucher.amount, currency) }}
                            </p>
                        </div>

                        <div class="bordered-message bg-red-100 text-center text-red-500" v-else-if="voucher_valid == false">
                            <h2>{{ $t('translations.modal.voucher-scan.status.invalid') }}</h2>
                            <p v-if="voucher.amount">{{ $t('translations.modal.voucher-scan.amount-available') }}
                                {{ $n(voucher.amount, currency) }}
                            </p>
                        </div>

                        <div v-else
                             class="bordered-message text-center">
                            <h3 class="">
                                {{ $t('translations.modal.voucher-scan.status.idle') }}
                            </h3>
                        </div>

                        <div class="button-flexer justify-end" v-if="!voucher_in_header">

                            <button type="button" @click="close()"
                                    class="btn btn-secondary">
                                {{ $t('translations.modal.voucher-scan.button-cancel') }}
                            </button>

                            <button v-if="partial_payment === true && voucher_valid === true" type="button" @click="doPartialPayment()"
                                    class="btn btn-primary">
                                {{ $t('translations.modal.voucher-scan.button-payment-partial') }}
                            </button>
                            <button v-if="partial_payment === false && voucher_valid ===true" type="button" @click="doFullPayment()"
                                    class="btn btn-primary">
                                {{ $t('translations.modal.voucher-scan.button-payment') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// libs
import * as _api from "../lib/api";
import * as _log from '../lib/log';
import * as _state from "../lib/state";
import * as _transaction from "../lib/transaction";

// mixins
import BarcodeReader from '../mixins/barcode.reader.js';

// set the logger
const logger = _log.get('ModalVoucher');

export default {
    name: 'modal.voucher',
    mixins: [BarcodeReader],
    props: {
        voucher_in_header: {
            default: false,
            type: Boolean,
        }
    },
    components: {},
    data () {
        return {
            paymenttype_id: null,
            active: false,
            paymenttype: false,
            voucher_valid: null,
            voucher_message: '',
            voucher: false,
        };
    },

    methods: {
        open (paymenttype_id) {

            this.paymenttype_id = paymenttype_id;

            this.initBarcodeScanner();

            this.reset();
            this.active = true;
        },

        close () {
            this.active = false;

            this.destroyBarcodeScanner();
        },

        reset () {
            this.voucher_valid = null;
            this.voucher_message = null;
            this.voucher = false;
        },

        async doPartialPayment () {

            // lock checkout
            _state.setField('status/getStatus', 'status/setStatus', 'basket_locked', true);

            // send the payment
            let response = await this.sendPayment();

            if (response === true) {
                this.close();
            } else {
                logger.log('invalid action')();
            }
        },

        async doFullPayment () {

            // send the payment
            let response = await this.sendPayment();

            if (response === true) {
                this.$emit('paid');
            } else {
                logger.log('invalid action')();
            }
        },

        async sendPayment () {

            let amount = 0;

            if (this.transaction.total_unpaid > this.voucher.amount) {
                amount = this.voucher.amount;
            } else {
                amount = this.transaction.total_unpaid;
            }

            // check the voucher code
            let response = await _api.post('voucher/payment', {
                vouchercode: this.voucher.vouchercode,
                amount: amount,
            });

            if (response && response.result == 'success') {
                _transaction.addPayment({
                    amount: response.amount,
                    mutation_id: response.mutation_id,
                    paymenttype_id: parseInt(this.paymenttype_id),
                    status: 'paid',
                    name: 'voucher: ' + response.vouchercode.substring(1, 4) + '....',
                    paid_at: response.timestamp,
                    vouchercode: this.voucher.vouchercode,
                });
                return true
            } else {
                // todo error
                console.log('error', response);
                return false
            }
        },

        async barcodeScanned (barcode) {

            // resert values
            this.reset();

            let vouchercode_exists = this.transaction.payments.filter(payment => {
                return payment.vouchercode == barcode;
            }).length > 0;

            if (vouchercode_exists === true) {
                this.voucher_valid = false;
                return;
            }

            // check the voucher code
            let response = await _api.post('voucher/check', {
                vouchercode: barcode,
                amount: this.transaction.total,
            });

            if (response && response.result) {
                this.voucher_valid = response.result ? true : false;

                if (this.voucher_valid === true) {
                    this.voucher = response;
                }
            } else {
                this.voucher_valid = false
            }
        },

    },
    computed: {
        status () {
            return _state.get('status/getStatus');
        },

        transaction () {
            return _state.get('transaction/getTransaction');
        },

        partial_payment () {
            return this.transaction.total_unpaid > this.voucher.amount;
        },

        hasFakeScan() {
            return process.env.VUE_APP_DEVELOPMENT == 'true' ? true : false
        },
        config () {
            return _state.get('config/getConfig');
        },

        currency(){
            return this.config.currency;
        },
    },
};
</script>
