<script>
    // libs
    import * as _state from "../lib/state";

    export default {
        emits: ['reset'],
        name: "AutoLogout",

        data: function () {
            return {
                events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress'],
                autoCancelTimer: null,
                duration: null, //default is set to 30 seconds
            }
        },

        async mounted () {

            this.duration = this.config.theme.autocancel_duration * 1000;

            //add event listener to all events that will reset the timer
            this.events.forEach(function (event) {
                window.addEventListener( event , this.setTimers);
            }, this);

            if (this.config.theme.user_mode != 'snoeien') {
                this.setTimers();
            }
        },

        methods: {
            setTimers: function () {
                if (Window.autoCancelTimer != null) {
                    clearTimeout(Window.autoCancelTimer);
                }

                Window.autoCancelTimer = setTimeout(this.cancelMessage, this.duration);
            },

            cancelMessage: function () {
                if (this.config.theme.user_mode == 'snoeien' && this.transaction.lines.length == 0) {
                    return;
                }

                if(this.status.autocancel_disabled){
                    return
                }

                this.$emit('reset');

            },

            test() {
                return false
            }
        },

        computed: {
            transaction () {
                return _state.get('transaction/getTransaction');
            },

            config() {
                return _state.get('config/getConfig');
            },

            status () {
                return _state.get('status/getStatus');
            },
        }
    }
</script>
