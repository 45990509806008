import axios from 'axios';
import * as _state from "../lib/state";
import * as _log from './log';

// set the logger
const logger = _log.get('API');

let _token = false;

const endpoints = {
    'categories': () => {
        return '/v1/kiosk/categories';
    },
    // 'events': () => {
    //     return '/v1/kiosk/events';
    // },
    'areas': () => {
        return '/v1/kiosk/areas';
    },
    'items/search-barcode': () => {
        return '/v1/kiosk/items/search-barcode';
    },
    'items/toggle-activity': () => {
        return '/v1/kiosk/items/toggle-activity';
    },
    'payment': () => {
        return '/v1/kiosk/payment';
    },
    'payment/cancel': () => {
        return '/v1/kiosk/payment/cancel';
    },
    'payment/status': () => {
        return '/v1/kiosk/payment/status';
    },
    'paymenttypes': () => {
        return '/v1/kiosk/paymenttypes';
    },
    'terminal': () => {
        return '/v1/kiosk/terminal';
    },
    'terminal/set-event': () => {
        return '/v1/kiosk/terminal/set-event';
    },
    'terminal/set-area': () => {
        return '/v1/kiosk/terminal/set-area';
    },
    'terminal/set-backup-printer': () => {
        return '/v1/kiosk/terminal/set-backup-printer';
    },
    'transaction': () => {
        return '/v1/kiosk/transactions/store';
    },
    'transaction/cancel': () => {
        return '/v1/kiosk/transactions/cancel';
    },
    'transaction/update': () => {
        return '/v1/kiosk/transactions/update';
    },
    'transaction/mail': () => {
        return '/v1/kiosk/transaction/mail';
    },
    'transaction/latest': () => {
        return '/v1/kiosk/transactions/latest';
    },
    'getsupport': () => {
        return '/v1/kiosk/getsupport';
    },
    'cashfunctions/wallet': () => {
        return '/v1/kiosk/cashfunctions/wallet';
    },

    // elockers
    'elockers/events': () => {
        return '/v1/kiosk/integration/elockers/events?filter[active]=true';
    },
    'elockers/reserve': () => {
        return '/v1/kiosk/integration/elockers/tickets/reserve';
    },
    'elockers/release': () => {
        return '/v1/kiosk/integration/elockers/tickets/release';
    },

    'coupon/check': () => {
        return '/v1/kiosk/coupon/check';
    },

    'voucher/check': () => {
        return '/v1/kiosk/voucher/check';
    },

    'voucher/payment': () => {
        return '/v1/kiosk/voucher/payment';
    },

    'wallet/token/check': () => {
        return '/v1/kiosk/wallet/token/check';
    },

    'wallet/token/payment': () => {
        return '/v1/kiosk/wallet/token/payment';
    },

    'nfc/read': () => {
        return '/v1/kiosk/nfc/read';
    },

    'nfc/abort': () => {
        return '/v1/kiosk/nfc/abort';
    },
}

const login = async ( client_code, terminal_code, terminal_pincode ) => {
    const endpoint = '/v1/kiosk/auth/login';

    return axios.post(endpoint, {
        'client_code': client_code,
        'terminal_code': terminal_code,
        'terminal_pincode': terminal_pincode
    }).then(response => {

        if (response.data && response.data.token && response.data.message && response.data.message === 'Authenticated'){
            _state.set('token/setToken', response.data.token);

            localStorage.setItem('ident', JSON.stringify({
                clientId: response.data.client_id,
                clientCode: response.data.client_code,
                terminalId: response.data.terminal_id,
                terminalCode: response.data.terminal_code,
            }))

            return response.data.token;
        } else {
            _state.set('token/setToken', false);
            return false;
        }
    }).catch(error => {
        _state.set('token/setToken', false);
        return false;
    });
}

const getToken = async (force = false) => {
    let _token = force ? false : _state.get('token/getToken');

    if(!_token){
        return false;
    } else {
        return _token;
    }
}

const get = async ( endPointId, anonymous = false ) => {
    let endPoint;
    if(!anonymous) _token = await getToken();
    if(!anonymous && !_token){
        logger.error('API getToken FAILED prior to GET {}', endPointId)();
        return false;
    }
    try {
        if (endPointId.substr(0,1) !== '/'){
            if(!endpoints[endPointId]) return [];
            endPoint = endpoints[endPointId]();
            if(!endPoint){
                let err = new Error('Endpoint ${endPointId} not found');
                err.status = 404;
                err.message = 'Endpoint ${endPointId} not found'
                throw err;
            }
        } else {
            endPoint = endPointId;
        }

        let response = await axios.get(endPoint, {
            timeout: 15000,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': anonymous ? '' : 'Bearer ' + _token
            }}).then(response => {
                return response.data.data
            })

        return 'data' in response ? response.data : response;

    } catch(error) {
        logger.error('API GET {} ERROR: {}', endPoint, error)();

        if(error.response.status === 401) {
            _state.set('token/setToken', false);
            window.location = '/login';
        }
        return false;
    }
}

const post = async ( endPointId, data, anonymous = false ) => {
    let endPoint;
    if(!anonymous) _token = await getToken();
    if(!anonymous && !_token){
        logger.error('API getToken FAILED prior to GET {}', endPointId)();
        return false;
    }
    try {
        if (endPointId.substr(0,1) !== '/'){
            if(!endpoints[endPointId]) return [];
            endPoint = endpoints[endPointId]();
            if(!endPoint){
                let err = new Error('Endpoint ${endPointId} not found');
                err.status = 404;
                err.message = 'Endpoint ${endPointId} not found'
                throw err;
            }
        } else {
            endPoint = endPointId;
        }

        try {
            const response = await axios.post(endPoint, data, {
              // timeout: 2000,
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': anonymous ? '' : 'Bearer ' + _token
              }
            });
            return response.data;
          } catch (error) {
            logger.error(`API GET ${endPoint} ERROR: ${error}`);
            if (error.response && error.response.status === 401) {
              _state.set('token/setToken', false);
              window.location = '/login';
            }
            return false;
        }
        // let response = await axios.post(endPoint, data, {
        //     // timeout: 2000,
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json',
        //         'Authorization': anonymous ? '' : 'Bearer ' + _token
        //     }}).then(response => {
        //         return response.data;
        //     }).catch(error => {
        //         logger.error('API GET {} ERROR: {}', endPoint, error)();
        //         console.log('111');
        //         if(error.response && error.response.status === 401) {
        //             _state.set('token/setToken', false);
        //             window.location = '/login';
        //         }

        //         return false;
        //     })

        // return response;
        // return 'data' in response ? response.data : response;

    } catch(error) {
        logger.error('API GET {} ERROR: {}', endPoint, error)();
        // console.log('error', error)
        if(error && error.response.status === 401) {
            _state.set('token/setToken', false);
            window.location = '/login';
        }
        return false;
    }
}

export {
    get,
    post,
    login,
    getToken
}
