<template>
    <!-- The video -->
    <div class="video-wrapper h-screen" @click="init()">
        <video autoplay loop muted class="w-full">
            <source :src="config.video_landing_url" type="video/mp4" />Your browser does not support the video tag.
        </video>
    </div>

    <!-- modals -->
    <scanner @initTransaction="init()" :initTransaction="true" :hideFakeScan="true"/>
</template>

<script>
//libs
import * as _state from "../lib/state";
import * as _log from '../lib/log';
import * as _transaction from '../lib/transaction';

// components
import Scanner from '../components/Scanner.vue';

// set the logger
const logger = _log.get('LANDING');

export default {
    name: 'landing',
    components: {
        Scanner,
    },

    data () {
        return {
            initpage: 'menu.selection'
        }
    },

    mounted() {
        // reboot?
        if (this.config.reboot === true) {
            this.$router.replace({ name: 'boot', params: { browserreload: 1 } })
        }

        // set default language
        this.setLanguageToDefault();


        //go to menu.selection if snoeien
        if (this.config.theme.user_mode === 'snoeien') {
            return this.init();
        }

        // set the initpage
        if (this.config.theme.flow == 'default') {
            logger.log('flow -> menu.selection (default)')();
            this.initpage = 'menu.selection';
        }
        else if (this.config.theme.flow == 'barcodescanner') {
            logger.log('flow -> barcode.scanner')();
            this.initpage = 'barcode.scanner';
        }
    },

    methods: {

        init() {

            // clear old payment
            // paymentClear();

            // create a new transaction
            _transaction.init();

            // redirect/replace
            this.$router.replace({ name: this.initpage });
        },

        setLanguageToDefault() {
            let config = this.config

            // set the language back to the default
            config.language = config.language_default

            // this.$i18n.locale = config.language;

            _state.set('config/setConfig', config);
        }
    },

    computed: {
        config() {
            return _state.get('config/getConfig')
        }
    }
}
</script>
