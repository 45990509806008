<template>
    <div v-if="status && status.ticket_max_tickets" class="fixed z-100 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal modal-full transform padding-wrapper">
                <div class="modal-inner">
                    <h1 class="" id="modal-title">
                        {{ $t('translations.modal.ticket-max-tickets.title') }}
                    </h1>
                    <p>{{ $t('translations.modal.ticket-max-tickets.description') }}</p>
                    <div class="button-flexer">
                        <button type="button" @click="close()"
                                class="inline-flex justify-center btn btn-secondary">
                            {{ $t('translations.modal.ticket-max-tickets.button-confirm') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// libs
import * as _state from "../lib/state";

export default {
    name: 'modal.ticket.max_tickets',
    components: {},
    data () {
        return {
            active: false,
        };
    },
    methods: {
        open () {
            this.active = true;
        },

        close () {
            this.active = false;

            _state.setField('status/getStatus', 'status/setStatus', 'ticket_max_tickets', false);
            // _state.setField('status/getStatus', 'status/setStatus', 'item_loading', false);
            // let status = _state.get('status/getStatus');

            // status.ticket_max_tickets = false;

            // _state.set('status/setStatus', status);
        },
    },
    computed: {
        status () {
            return _state.get('status/getStatus');
        },
    },
};
</script>
