import * as _log from '../lib/log';
import * as _state from "../lib/state";
import * as _api from "../lib/api";

// set the logger
const logger = _log.get('PRINTER');
// let initialConfig = _state.get('config/getConfig');
let printData;

const print = (data) => {

    logger.log('start printing../')();
    printData = data;

    //redefine config when backup printer has been set
    let config = _state.get('config/getConfig');

    // eslint-disable-next-line no-undef
    let printer = new StarWebPrintTrader({
        url: config.printer_address + '/StarWebPRNT/SendMessage'
    });


    printer.onReceive = function (response) {

        if (printer.isCoverOpen({ traderStatus: response.traderStatus })) {
            setError('cover is open');
            return;
        }

        if (printer.isHighTemperatureStop({ traderStatus: response.traderStatus })) {
            setError('high temperature');
            return;
        }

        if (printer.isNonRecoverableError({ traderStatus: response.traderStatus })) {
            setError('Printer fatal error');
            return;
        }

        if (printer.isAutoCutterError({ traderStatus: response.traderStatus })) {

            setError('auto cutter error');
            return;
        }

        if (printer.isBlackMarkError({ traderStatus: response.traderStatus })) {
            setError('black mark error');
            return;
        }

        if (printer.isPaperEnd({ traderStatus: response.traderStatus })) {
            setError('out of paper');
            return;
        }

        if (printer.isPaperNearEnd({ traderStatus: response.traderStatus })) {
            setError('out of paper');
            return;
        }

        if (printer.isOffLine({ traderStatus: response.traderStatus })) {
            setError('offline');
            // store.commit('notifications/ADD_NOTIFICATION', { group: "notifications", type: 'error', title: "Printer is offline", text: "Controleer de netwerkverbinding" });
        }

        if (response.traderSuccess) {
            // $store.dispatch('printer/removePrintjob');

            // if (jobsWaiting > 0) {
            //     print()
            // } else {
            //     busy = false;
            // }
        }
        return 'success';
    }

    printer.onError = function (error) {
        console.log(error)
        // logger.log('error')();
        return;
    }

    printer.sendMessage({
        request: data
    });

    return;
}

const setError = async (error) => {
    let config = _state.get('config/getConfig');

    logger.log('error', error)();

    if (!config.printer_backup_address.includes('null') && !config.printer_backup_address.includes('undefined')) {
        console.log('no null and no undefined');

        await setBackupPrinter(error);

        return;
    } 
    // else {
    //     _state.setField('status/getStatus', 'status/setStatus', 'printer_error', error);
    // }

    // if (config.printer_backup_address.includes('null') === true ||  config.printer_backup_address.includes('undefined') === true) {
    //     sendError(type, error);
    // }
}

const setBackupPrinter = async (error) => {
    let config = _state.get('config/getConfig');

    // set backup printer in kantoor

    let response = await _api.post('terminal/set-backup-printer');

    console.log('responsePrinterBackup', response);

    // handle the response
    if (response && response.result === 'success') {
        console.log('...printer switched backend')
    } else {
        _state.setField('status/getStatus', 'status/setStatus', 'printer_error', error);
    }
    
    // if (allebei de printers hebben een error of er bestaat geen backupprinter) {
    //     _state.setField('status/getStatus', 'status/setStatus', 'printer_error', error);
    // }
    
    // Switch printers locally
    let printerAddress = config.printer_address;
    _state.setField('config/getConfig', 'config/setConfig', 'printer_address', config.printer_backup_address);
    _state.setField('config/getConfig', 'config/setConfig', 'printer_backup_address', printerAddress);

    print(printData);
};


 const reset = (error) => {
    logger.log('reset printer error')();

    _state.setField('status/getStatus', 'status/setStatus', 'printer_error', false);
}


export {
    print,
    reset
}
