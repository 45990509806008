
import * as _api from './api';
import * as _log from './log';
// import app from '../app';

import PouchPluginUpsert from 'pouchdb-upsert';
import PouchFindPlugin from 'pouchdb-find';

const logger = _log.get('DB');

import PouchDB from 'pouchdb';

let _db;

const db = {};

db.initialize = async () => {

    logger.warn('DB POUCHDB BROWSER MODE')();

    PouchDB.plugin(PouchPluginUpsert);
    PouchDB.plugin(PouchFindPlugin);

    _db = new PouchDB('sjef_epos.db', { revs_limit: 1, auto_compact: true });

    logger.log('DB INITIALIZED')();
    db._db = _db;

    return;
}


db.sync = async () => {

    logger.debug('DB SYNCING')();

    await db.get('paymenttypes', true);
    await db.get('categories', true);
    await db.get('terminal', true);

    logger.log('DB SYNCED')();

}

db.get = async (id, syncOnline = false) => {

    var doc = {},
        d = false;

    try {
        logger.debug('get {} {}', id, syncOnline ? 'syncOnline' : 'cache')();
        doc = await _db.get(id);
        d = doc[id];

    } catch (err) {
        if (err.status && err.status === 404) {
            logger.warn('get could not find {} {}', id, doc)();
            d = [];
        }else{
            logger.warn('_db get error {} {}', id, err)();
        }
    }

    try {
        if (!d || syncOnline) {
            logger.debug('api get {} {}', id, syncOnline ? 'syncOnline' : 'cache')();
            d = await _api.get(id);

            if (d && (Array.isArray(d) || Object.keys(d).length)) {
                if (!doc['_id']) doc = { _id: id };

                doc[id] = d;
                // eslint-disable-next-line no-unused-vars
                await _db.upsert(id, olddoc => { return doc });

            } else {
                logger.debug('api get result empty, not upserting {} {}', id, d)();
            }
        }
        return d ? d : [];

    } catch (err) {
        logger.warn('api get error {}', err)();
        return [];
    }
}

db.set = async (id, data) => {
    try {
        logger.debug('set upsert {}', id)();
        let d = {};
        d[id] = data;

        // eslint-disable-next-line no-unused-vars
        await _db.upsert(id, doc => { return d });

        return true;
    } catch (err) {
        logger.warn('set error {}', err)();
        return false;
    }
}

db.remove = async (id) => {
    try {
        logger.debug('delete {}', id)();

        let result = await _db.remove(id);

        return result.ok || false;
    } catch (err) {
        logger.warn('remove error {}', err)();
        return false;
    }
}


// db.reset = async () => {
//     try{
//         await _db.destroy();
//         await db.initialize();
//         await db.sync();
//     }catch(err){
//         logger.error('reset error {}', err);
//     }
// }

export default db;
