import { createRouter, createWebHistory } from 'vue-router'
import BarcodeScanner from '../views/BarcodeScanner.vue'
import Boot from '../views/Boot.vue'
import Checkout from '../views/Checkout.vue'
import Home from '../views/Home.vue'
import Landing from '../views/Landing.vue'
import Login from '../views/Login.vue'
import MenuSelection from '../views/MenuSelection.vue'
import Payment from '../views/Payment.vue'

import { getToken } from '../lib/api';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/boot',
        name: 'boot',
        component: Boot,
        props: true,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/',
                name: 'landing',
                component: Landing,
            },
            // {
            //     path: '/boot',
            //     name: 'boot',
            //     component: Boot,
            //     props: true,
            // },

            // layout/flows
            {
                path: '/barcode-scanner',
                name: 'barcode.scanner',
                component: BarcodeScanner,
            },
            {
                path: '/menu-selection',
                name: 'menu.selection',
                component: MenuSelection,
            },

            // checkout/payment
            {
                path: '/checkout',
                name: 'checkout',
                component: Checkout,
            },
            {
                path: '/payment',
                name: 'payment',
                component: Payment,
                props: true,
            },
        ]
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        let token = await getToken();

        if (token) {
            next();
            return;
        }
        next('/login');
    } else {
        let token = await getToken();

        if (token.length > 0 && to.name == 'login') {
            next('/');
            return;
        }
        next()
    }
})

export default router
