<template>
    <div class="countdown-wrapper text-2xl" v-if="type == 'elockers' && ticketCounter > 0">
        <vue-countdown @end="end" @progress="progress" :time="status.elockers_timer" v-slot="{ minutes, seconds }">
            {{ ('00'+ minutes).slice(-2) }}:{{ ('00'+ seconds).slice(-2) }}
        </vue-countdown>
    </div>
</template>

<script>

// libs
import * as _elockers from "../lib/elockers";
import * as _log from '../lib/log';
import * as _state from "../lib/state";

// components
import VueCountdown from '@chenfengyuan/vue-countdown';

// set the logger
const logger = _log.get('Countdown');

export default {
    name: 'countdown',
    props: ['type'],
    components: {
        VueCountdown,
    },
    methods: {
        progress(event) {
            _state.setField('status/getStatus', 'status/setStatus', 'elockers_timer', event.totalMilliseconds);
        },

        end() {
            if (this.type == 'elockers') {
                _state.setField('status/getStatus', 'status/setStatus', 'elockers_timer', false);
            }
            _state.setField('status/getStatus', 'status/setStatus', 'ordertime_exceeded', true);
        },
    },
    computed: {
        status () {
            return _state.get('status/getStatus');
        },
        ticketCounter () {
            return _elockers.ticketCounter();
        },
    },
};
</script>
