export default {
    namespaced: true,
    state: {
        token: false
    },

    getters: {
        getToken: state => state.token ? state.token : false,
    },

    mutations: {
        TOKEN(state, token) {
            state.token = token;
        },
    },

    actions: {
        setToken({ commit }, token) {
            commit('TOKEN',token);
        },
    }
}
