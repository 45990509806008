<template>
    <div class="modal modal-full padding-wrapper transform payment-modal">
        <template v-if="config.theme.device == 'S1U2'">
            <div class="modal-inner">
                <h1 class="text-center">{{ $t('translations.payment.success.confirmation.description') }}</h1>
            </div>
        </template>
        <template v-else>
            <confetti v-if="show_celebration" />
            <div class="modal-inner">
                <h1 class="text-center">{{ $t('translations.payment.success.title') }}</h1>
                <h1 class="text-center confetti" v-if="show_celebration">{{ $t('translations.checkout.order-fastest')}} </h1>
                <div class="payment-icon">
                    <svg width="270px" height="270px" viewBox="0 0 270 270" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Ellipse-2" transform="translate(4.000000, 4.000000)" stroke="#FFF200">
                                <circle id="Oval" stroke-width="8" cx="131" cy="131" r="131"></circle>
                                <path
                                    d="M78,126.7027 L116.7947,166.255 C117.1931,166.6612 117.8497,166.6536 118.2386,166.2383 L184,96"
                                    id="Path" stroke-width="10" stroke-linecap="round"></path>
                            </g>
                        </g>
                    </svg>
                </div>
                <!--            <p class="text-center">{{ $t('translations.payment.success.description') }}</p>-->
                <div class="text-center pt-4 large-buttons"
                    v-if="!asked_for_email && config.theme.show_email_options_after_payment">
                    <button class="btn btn-primary" @click="asked_for_email = !asked_for_email">
                        {{ $t('translations.payment.success.email.ask-for-email') }}
                    </button>
                    <button @click="goToMenuSelection()" v-if="config.theme.user_mode === 'snoeien'"
                            class="btn btn-secondary" style="margin-left: 1rem;">
                        {{ $t('translations.payment.success.email.button-no') }}
                        ({{ countdown_seconds_email }})
                    </button>
                    <button @click="gotoLanding()" v-else
                            class="btn btn-secondary" style="margin-left: 1rem;">
                        {{ $t('translations.payment.success.email.button-no') }}
                        ({{ countdown_seconds_email }})
                    </button>
                </div>

                <div class="text-center large-buttons" v-if="!config.theme.show_email_options_after_payment">
                    <a @click="goToMenuSelection()" v-if="config.theme.user_mode === 'snoeien'"
                    class="btn btn-secondary">
                        {{ $t('translations.payment.success.confirmation.button-goback') }}
                        ({{ countdown_seconds_email }})
                    </a>
                    <a @click="gotoLanding()" v-else
                    class="btn btn-secondary">
                        {{ $t('translations.payment.success.confirmation.button-goback') }}
                        ({{ countdown_seconds_email }})
                    </a>
                </div>

                <!-- PAGE: emailtransaction -->
                <div v-if="page == 'emailtransaction' && asked_for_email" class="">
                    <p>{{ $t('translations.payment.success.email.description') }}</p>

                    <!-- (create spinner and disable buttons) -->
                    <form action="#" @submit.prevent="transactionSendMail()" autocomplete="off"
                        class="payment-email-form relative br-receiver"
                        :class="{'sending': sending, 'sent' : sent}"
                    >
                        <div class="form-merge">
                            <input @click="showKeyboard()"
                                type="text" required
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                :placeholder="$t('translations.payment.success.email.input-placeholder-email')"
                                v-model="customer_email" name="customer_email" id="customer_email"
                                class="flex-grow outline-none lowercase">
                            <button class="btn btn-primary">
                                {{ $t('translations.payment.success.email.button-send') }}
                            </button>
                        </div>

                        <a @click="continueToNextPage()"
                        class="btn btn-secondary">
                            {{ $t('translations.payment.success.email.button-no') }}
                            ({{ countdown_seconds_email }})
                        </a>

                        <div v-if="sending" class="payment-success-email-message loading">
                            <svg class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                        <h2 v-if="sent" class="payment-success-email-message success">
                            {{ $t('translations.payment.success.email.sent') }}</h2>
                    </form>


                    <div class="fixed w-full keyboard-wrapper" :class="{'keyboard-open': keyboardActive}">
                        <div class="keyboard-outer" :class="{'keyboard-slide': keyboardActive}">
                            <keyboard @onchange="keyboardInput" :input="input" :emailKeyboard="true" />
                        </div>
                    </div>

                    <!-- idle state -->
                    <idle />
                </div>
                <!-- PAGE: takereceipt -->
                <div v-else-if="page == 'takereceipt'" class="flexer-center button-flexer">
                    <h1 v-if="config.printer_active">{{ $t('translations.payment.success.confirmation.description') }}</h1>

                    <a @click="goToMenuSelection()" v-if="config.theme.user_mode === 'snoeien'"
                    class=" btn btn-secondary">
                        {{ $t('translations.payment.success.confirmation.button-goback') }}
                        ({{ countdown_seconds_confirmation }})
                    </a>
                    <a @click="gotoLanding()" v-else
                    class=" btn btn-secondary">
                        {{ $t('translations.payment.success.confirmation.button-goback') }}
                        ({{ countdown_seconds_confirmation }})
                    </a>
                </div>
            </div>
        </template>
    </div>

</template>

<script>
import * as _api from "../lib/api";

// libs
import * as _payment from '../lib/payment';
import * as _state from "../lib/state";
import * as _transaction from '../lib/transaction';

// mixins
import IntervalTimeout from '../mixins/interval-timeout.helper.js';

// components
import Keyboard from "../components/Keyboard.vue";
import Idle from '../components/Idle.vue';
import Confetti from "./Confetti";

export default {
    name: 'payment.success',
    mixins: [IntervalTimeout],
    components: {
        Idle,
        Keyboard,
        Confetti,
    },
    data () {
        return {
            customer_email: '',
            sending: false,
            sent: false,
            page: 'emailtransaction',
            keyboardActive: false,
            interval_ids: [],
            countdown_seconds_confirmation: 15,
            countdown_seconds_email: 60,
            asked_for_email: false,
            show_celebration: false,
        };
    },
    mounted () {
        _transaction.print();

        this.countdownEmail();
        this.durationCheck();
    },
    unmounted () {
        _transaction.clear();
        _payment.clear();

        this.clearAllIntervals();
    },
    methods: {
        durationCheck () {

            let duration = Date.now() - this.transaction.start_time

            _api.post('transaction/update', {
                duration: duration,
                transaction_id: this.transaction.id,
            });

            if (this.config.theme.user_mode !== 'snoeien') {
                return;
            }

            //check for celebration
            if (duration < 8000) {
                this.show_celebration = true;
            } else {
                this.show_celebration = false;
            }
        },

        showKeyboard () {
            this.countdownEmail();
            this.keyboardActive = true;
        },
        keyboardInput (event) {
            this.customer_email = event;
        },

        continueToNextPage () {
            this.page = 'takereceipt';

            this.clearAllIntervals();
            this.countdownConfirmation();
        },

        goToMenuSelection () {
            this.$router.replace({ name: 'menu.selection' });
        },

        gotoLanding () {
            this.$router.replace({ name: 'landing' });
        },

        transactionPrint () {
            _transaction.print();
        },

        async transactionSendMail () {

            this.sending = true;

            let result = await _transaction.sendByMail(this.customer_email);

            if (result === true) {
                this.sent = true;
                this.continueToNextPage();
            }
            // todo: error message
            else {
                this.sending = false;
            }
        },

        countdownConfirmation () {
            this.countdown_seconds_confirmation = 10;

            this.clearAllIntervals();

            let interval_id = setInterval(() => {
                if (this.countdown_seconds_confirmation === 1) {
                    this.gotoLanding();
                }
                this.countdown_seconds_confirmation -= 1;
            }, 1000);

            this.interval_ids.push(interval_id);
        },

        countdownEmail () {
            this.countdown_seconds_email = 60;
            if (this.config.theme.user_mode === 'snoeien') {
                this.countdown_seconds_email = 5;
            }

            this.clearAllIntervals();

            let interval_id = setInterval(() => {
                if (this.countdown_seconds_email === 1) {
                    if (this.config.theme.user_mode === 'snoeien') {
                        this.goToMenuSelection();
                    } else {
                        this.gotoLanding();
                    }
                }

                this.countdown_seconds_email -= 1;
            }, 1000);

            this.interval_ids.push(interval_id);
        },
    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },

        transaction () {
            return _state.get('transaction/getTransaction');
        },
    },
};
</script>
