<template>

    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
         aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-left sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal inline-block modal-full transform padding-wrapper">
                <div class="modal-inner">
                    <button @click="close()" type="button" class="close-button">
                        <span class="sr-only">{{ $t('translations.global.close') }}</span>
                        <svg class="h-14 w-14" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <h1 class="" id="modal-title">
                        {{ $t('translations.modal.coupon-scan.title') }}
                    </h1>

                    <a v-if="hasFakeScan == true" @click="barcodeScanned(100)" class="underline block">Do Fake Scan</a>

                    <div class="bordered-message">
                        <h2 v-if="coupon_valid === true" class="text-center font-bold text-green-500">
                            {{ $t('translations.modal.coupon-scan.status.valid') }}
                        </h2>

                        <h3 v-else-if="coupon_valid == false" class="text-center font-bold text-red-500">
                            {{ $t('translations.modal.coupon-scan.status.invalid') }}
                        </h3>

                        <h3 v-else>
                            {{ $t('translations.modal.coupon-scan.status.idle') }}
                        </h3>
                    </div>

                    <div class="button-flexer justify-end">
                        <button v-if="coupon_valid === true" type="button" @click="addCoupon()"
                                class="btn btn-primary">
                            {{ $t('translations.modal.coupon-scan.button-confirm') }}
                        </button>
                        <button type="button" @click="close()" class="btn btn-secondary">
                            {{ $t('translations.modal.coupon-scan.button-cancel') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// libs
import * as _api from "../lib/api";
import * as _log from '../lib/log';
import * as _state from "../lib/state";
import * as _transaction from "../lib/transaction";

// mixins
import BarcodeReader from '../mixins/barcode.reader.js';

// set the logger
const logger = _log.get('ModalCoupon');

export default {
    name: 'modal.coupon',
    mixins: [BarcodeReader],
    components: {},
    data () {
        return {
            active: false,
            coupon_valid: null,
            coupon_message: '',
            coupon: false,
        };
    },
    created () {
    },
    unmounted () {
    },
    methods: {
        open () {
            this.initBarcodeScanner();

            this.reset();
            this.active = true;
        },

        close () {
            this.active = false;

            this.destroyBarcodeScanner();
        },

        reset() {
            this.coupon_valid = null;
            this.coupon_message = null;
            this.coupon = false;
        },

        addCoupon () {
            _transaction.addCoupon(this.coupon);

            this.close();
        },

        async barcodeScanned (barcode) {

            let couponcode_exists = this.transaction.coupons.filter(coupon => {
                return coupon.code == barcode;
            }).length > 0;

            if (couponcode_exists === true) {
                this.coupon_valid = false;
                return;
            }

            // check the coupon code
            let response = await _api.post('coupon/check', {
                couponcode: barcode,
            });

            // set valid
            this.coupon_valid = response.result === 'success' ? true : false;

            if (this.coupon_valid === true) {
                this.coupon = response.coupon;
            }
        },
    },
    computed: {
        status () {
            return _state.get('status/getStatus');
        },

        transaction () {
            return _state.get('transaction/getTransaction');
        },

        hasFakeScan() {
            return process.env.VUE_APP_DEVELOPMENT == 'true' ? true : false
        }
    },
};
</script>
