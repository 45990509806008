<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-900 py-12 px-4 sm:px-6 lg:px-8" :class="stack">
    <div class="max-w-md w-full login">
        <div>
            <div class="flex justify-center">
                <img v-if="stack == 'elockers'" class="h-12" src="../assets/img/logos/elockers_logo-white.png" alt="Elockers logo" />
                <img v-else class="h-12" src="../assets/img/logos/sjef_logo-white.png" alt="Sjef logo" />
            </div>
            <h2 class="mt-6 text-center text-2xl leading-9 font-extrabold text-white">
                {{ $t('translations.sign_in') }}
            </h2>
        </div>

        <div v-if="!loading">
            <form class="mt-8" action="#" @submit.prevent="login" autocomplete="off">
                <div class="rounded-md shadow-sm">
                    <div>
                        <input @click="setField('client_code')" placeholder="Bedrijfs CODE" v-model="client_code" autofocus name="client_code" type="text" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"  />
                    </div>
                    <div class="">
                        <input @click="setField('terminal_code')" placeholder="Terminal CODE" v-model="terminal_code" name="terminal_code" type="text" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" />
                    </div>
                    <div class="">
                        <input @click="setField('terminal_pincode')" placeholder="Pincode" v-model="terminal_pincode" name="terminal_pincode" type="password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" />
                    </div>
                </div>
                <div class="mt-2">
                    <numpad ref="numpad" @inputvalue="this[field] = $event"></numpad>
                </div>

                <div class="mt-3">
                    <button type="submit" class="btn btn-login w-full relative">
                        <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                            <svg class="h-5 w-5 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20">
                                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                            </svg>
                        </span>
                        Sign in
                    </button>
                </div>
            </form>
        </div>
        <div v-else>
            <div class="mt-10 flex justify-center">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <div class="text-white">
                    Loading...
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// libs
import * as _api from "../lib/api";

// components
import Numpad from "../components/Numpad.vue";

export default {
    name: 'login',
    components: {
        Numpad
    },
    data () {
        return {
            field: 'client_code',
            client_code: '',
            terminal_code: '',
            terminal_pincode: '',
        }
    },

    methods: {
        setField(field) {
            this.field = field;
            this.$refs.numpad.setValue(this[this.field]);
        },

        async login () {

            let response = await _api.login(this.client_code, this.terminal_code, this.terminal_pincode)

            if (response === false) {
                // todo: show errors in form
                return
            }

            this.$router.replace({ name: 'boot' })
        },
    },
    computed: {
        stack() {
            return process.env.VUE_APP_STACK ? process.env.VUE_APP_STACK : 'sjef'
        }
    }
}
</script>
