import { createApp } from 'vue';
import { createI18n } from 'vue-i18n'
import 'es6-promise/auto';
import Vuex from 'vuex';
import axios from 'axios';
import './assets/tailwind.css';
import './assets/scss/style.scss';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

import { getMessages, getNumberFormats } from './lib/i18n';
import router from './lib/router';
import states from './states';

import App from './App.vue';

let messages = getMessages();
let numberFormats = getNumberFormats();

const i18n = createI18n({
    locale: 'nl',
    messages,
    numberFormats,
})

axios.defaults.baseURL = process.env.VUE_APP_API_URL

createApp(App)
    .use(i18n)
    .use(Vuex)
    .use(router)
    .use(states)
    // .use(initLocales)
    // .use(loadLocales)
    .mount('#app')
