export default {
    namespaced: true,
    state: {
        status: JSON.stringify({
            basket_locked: false,
            elockers_timer: false,
            ordertime_exceeded: false,
            printer_error: false,
            ticket_unavailable_ticket: false,
            ticket_max_tickets: false,
            item_adding: false,
            autocancel_disabled: false,
        })
    },

    getters: {
        getStatus: state => state.status,
    },

    mutations: {
        STATUS(state, status) {
            state.status = status;
        },
    },

    actions: {
        setStatus({ commit }, status) {
            commit('STATUS', status);
        },
    }
}
