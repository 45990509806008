<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full">
        <div>
            <div class="flex justify-center">
                <img v-if="stack == 'elockers'" class="h-12" src="../assets/img/logos/elockers_logo-white.png" alt="Elockers logo" />
                <img v-else class="h-12" src="../assets/img/logos/sjef_logo-white.png" alt="Sjef logo" />
            </div>
            <h2 class="mt-6 text-center  leading-9 text-white">
                <div class="flex justify-center items-center">
                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Loading KIOSK
                </div>
            </h2>
        </div>
    </div>
  </div>
</template>

<script>
import * as _state from "../lib/state";
import db from "../lib/db";
import * as _log from '../lib/log';

// set the logger
const logger = _log.get('BOOT');

export default {
    name: 'boot',
    props: ['browserreload'],
    mounted() {
        // check if the kiosk need a reload
        if (!!this.browserreload == true) {

            logger.log('reloading page..')();

            window.location.reload();

            return;
        }

        _state.setField('status/getStatus', 'status/setStatus', 'item_adding', false);


        this.boot()
    },
    methods: {
        async boot () {
            logger.log('booting..')()

            // todo: to boot
            await db.initialize();
            await db.sync();

            let paymenttypes = await db.get('paymenttypes');
            let categories = await db.get('categories');
            let terminal = await db.get('terminal');

            // initilize config
            this.setConfig({
                default_items: terminal.relationships.default_items ? terminal.relationships.default_items : [],

                // elockers
                elockers_config_required: terminal.attributes.elockers_config_required,
                // integration_elockers_event_id: terminal.attributes.integration_elockers_event_id,
                // integration_elockers_location_id: terminal.attributes.integration_elockers_location_id,
                // integration_elockers_area_id: terminal.attributes.integration_elockers_area_id,

                // event
                event: terminal.relationships.event ? terminal.relationships.event.attributes.name : '',
                event_date: terminal.relationships.event ? terminal.relationships.event.attributes.from : '',

                language: terminal.attributes.language,
                language_default: terminal.attributes.language,
                // layout: terminal.attributes.layout,

                currency: terminal.attributes.currency,

                logo_url: terminal.attributes.logo_url,
                reboot: false,
                terminal_id: terminal.id,
                pincode: terminal.attributes.pincode,
                pincode_admin: terminal.attributes.pincode_admin,
                printer_active: terminal.attributes.printer_id > 0 ? true : false,
                printer_address: terminal.attributes.printer_protocol + '://' + terminal.attributes.printer_ipaddress,
                printer_backup_address: terminal.attributes.printer_backup_protocol + '://' + terminal.attributes.printer_backup_ipaddress,
                printer_paperwidth: terminal.attributes.printer_paperwidth ? terminal.attributes.printer_paperwidth : '58mm',
                receipt_type: terminal.attributes.receipt_type ? terminal.attributes.receipt_type : 'default',

                background_header_url: terminal.attributes.background_header_url,
                background_footer_url: terminal.attributes.background_footer_url,
                background_modal_url: terminal.attributes.background_modal_url,

                video_landing_url: terminal.attributes.video_landing_url,
                video_footer_url: terminal.attributes.video_footer_url,

                theme_url: terminal.attributes.theme_url,
                theme: {
                    user_mode: terminal.attributes.theme_settings.user_mode ? terminal.attributes.theme_settings.user_mode : 'default',
                    product_ordering: terminal.attributes.theme_settings.product_ordering ? terminal.attributes.theme_settings.product_ordering : 'stack',
                    autocancel_duration: terminal.attributes.theme_settings.autocancel_duration ? terminal.attributes.theme_settings.autocancel_duration : 30,
                    allow_coupons: terminal.attributes.theme_settings.allow_coupons ? Boolean(parseInt(terminal.attributes.theme_settings.allow_coupons)) : true,
                    extra_email_domains: terminal.attributes.theme_settings.extra_email_domains ? terminal.attributes.theme_settings.extra_email_domains : '',
                    pin_terminal_position: terminal.attributes.theme_settings.pin_terminal_position ? terminal.attributes.theme_settings.pin_terminal_position : 'bottom',
                    direct_pin_payment: terminal.attributes.theme_settings.direct_pin_payment ? Boolean(parseInt(terminal.attributes.theme_settings.direct_pin_payment)) : false,
                    wallet_topup_mode: terminal.attributes.theme_settings.wallet_topup_mode ? terminal.attributes.theme_settings.wallet_topup_mode : 'qr manual',
                    language_switcher_mode: terminal.attributes.theme_settings.language_switcher_mode ? terminal.attributes.theme_settings.language_switcher_mode : 'top',
                    voucher_button_in_header: terminal.attributes.theme_settings.voucher_button_in_header ? Boolean(parseInt(terminal.attributes.theme_settings.voucher_button_in_header)) : false,
                    wallet_button_in_header: terminal.attributes.theme_settings.wallet_button_in_header ? Boolean(parseInt(terminal.attributes.theme_settings.wallet_button_in_header)) : false,
                    support_button_in_header: terminal.attributes.theme_settings.support_button_in_header ? Boolean(parseInt(terminal.attributes.theme_settings.support_button_in_header)) : false,
                    header_height: terminal.attributes.theme_settings.header_height ? terminal.attributes.theme_settings.header_height : 100,
                    header_border: terminal.attributes.theme_settings.header_border ? terminal.attributes.theme_settings.header_border : 'border',
                    header_border_width: terminal.attributes.theme_settings.header_border_width ? terminal.attributes.theme_settings.header_border_width : 0,
                    header_image_position_x: terminal.attributes.theme_settings.header_image_position_x ? terminal.attributes.theme_settings.header_image_position_x : 'center',
                    header_image_position_y: terminal.attributes.theme_settings.header_image_position_y ? terminal.attributes.theme_settings.header_image_position_y : 'center',
                    header_image_size: terminal.attributes.theme_settings.header_image_size ? terminal.attributes.theme_settings.header_image_size : '100%',
                    footer_height: terminal.attributes.theme_settings.footer_height ? terminal.attributes.theme_settings.footer_height : 100,
                    footer_border: terminal.attributes.theme_settings.footer_border ? terminal.attributes.theme_settings.footer_border : 'border',
                    footer_border_width: terminal.attributes.theme_settings.footer_border_width ? terminal.attributes.theme_settings.footer_border_width : 0,
                    footer_image_position_x: terminal.attributes.theme_settings.footer_image_position_x ? terminal.attributes.theme_settings.footer_image_position_x : 'center',
                    footer_image_position_y: terminal.attributes.theme_settings.footer_image_position_y ? terminal.attributes.theme_settings.footer_image_position_y : 'center',
                    footer_image_size: terminal.attributes.theme_settings.footer_image_size ? terminal.attributes.theme_settings.footer_image_size : '100%',
                    footer_video_style: terminal.attributes.theme_settings.footer_video_style ? terminal.attributes.theme_settings.footer_video_style : 'contain',
                    footer_column_1: terminal.attributes.theme_settings.footer_column_1 ? terminal.attributes.theme_settings.footer_column_1 : '',
                    footer_column_2: terminal.attributes.theme_settings.footer_column_2 ? terminal.attributes.theme_settings.footer_column_2 : '',
                    show_product_name: terminal.attributes.theme_settings.show_product_name ? Boolean(parseInt(terminal.attributes.theme_settings.show_product_name)) : true,
                    show_product_price: terminal.attributes.theme_settings.show_product_price ? Boolean(parseInt(terminal.attributes.theme_settings.show_product_price)) : true,
                    show_category_name: terminal.attributes.theme_settings.show_category_name ? Boolean(parseInt(terminal.attributes.theme_settings.show_category_name)) : true,
                    show_category_long_description: terminal.attributes.theme_settings.show_category_long_description ? Boolean(parseInt(terminal.attributes.theme_settings.show_category_long_description)) : true,
                    show_email_options_after_payment: terminal.attributes.theme_settings.show_email_options_after_payment ? Boolean(parseInt(terminal.attributes.theme_settings.show_email_options_after_payment)) : true,
                    product_text_style: terminal.attributes.theme_settings.product_text_style ? terminal.attributes.theme_settings.product_text_style : 'small',
                    add_product_icon: terminal.attributes.theme_settings.add_product_icon ? terminal.attributes.theme_settings.add_product_icon : 'plus',
                    basket_placement: terminal.attributes.theme_settings.basket_placement ? terminal.attributes.theme_settings.basket_placement : 'basket-right',
                    basket_width: terminal.attributes.theme_settings.basket_width ? terminal.attributes.theme_settings.basket_width : 'w-1/4',
                    button_radius: terminal.attributes.theme_settings.button_radius ? terminal.attributes.theme_settings.button_radius : 5,
                    category_images_style: terminal.attributes.theme_settings.category_images_style ? terminal.attributes.theme_settings.category_images_style : 'small',
                    card_radius: terminal.attributes.theme_settings.card_radius ? terminal.attributes.theme_settings.card_radius : 10,
                    device: terminal.attributes.theme_settings.device ? terminal.attributes.theme_settings.device : 'portrait-1920x1080',
                    flow: terminal.attributes.theme_settings.flow ? terminal.attributes.theme_settings.flow : 'default',
                    layout_columns: terminal.attributes.theme_settings.menu_columns ? terminal.attributes.theme_settings.menu_columns : 2,
                    interspace: terminal.attributes.theme_settings.interspace ? terminal.attributes.theme_settings.interspace : 2,
                    product_grid_decoration: terminal.attributes.theme_settings.product_grid_decoration ? terminal.attributes.theme_settings.product_grid_decoration : '',
                    product_tile_size: terminal.attributes.theme_settings.product_tile_size ? terminal.attributes.theme_settings.product_tile_size : 'auto',
                    product_text_position: terminal.attributes.theme_settings.product_text_position ? terminal.attributes.theme_settings.product_text_position : 'text-middle',
                    product_images_style: terminal.attributes.theme_settings.product_images_style ? terminal.attributes.theme_settings.product_images_style : 'small',
                    product_description: terminal.attributes.theme_settings.product_description ? terminal.attributes.theme_settings.product_description : 'none',
                    tile_layout: terminal.attributes.theme_settings.tile_layout ? terminal.attributes.theme_settings.tile_layout : 'horizontal',
                    modal_position_size: terminal.attributes.theme_settings.modal_position_size ? terminal.attributes.theme_settings.modal_position_size : 'bottom bottom-85 slide-bottom',
                    modal_image: terminal.attributes.theme_settings.modal_image ? Boolean(parseInt(terminal.attributes.theme_settings.modal_image)) : false,
                    modal_image_mode: terminal.attributes.theme_settings.modal_image_mode ? terminal.attributes.theme_settings.modal_image_mode : 'default',
                    show_categories: terminal.attributes.theme_settings.show_categories ? Boolean(parseInt(terminal.attributes.theme_settings.show_categories)) : true,
                    modal_background_image_position_x: terminal.attributes.theme_settings.modal_background_image_position_x ? terminal.attributes.theme_settings.modal_background_image_position_x : 'center',
                    modal_background_image_position_y: terminal.attributes.theme_settings.modal_background_image_position_y ? terminal.attributes.theme_settings.modal_background_image_position_y : 'center',
                    modal_background_image_size: terminal.attributes.theme_settings.modal_background_image_size ? terminal.attributes.theme_settings.modal_background_image_size : 'cover',
                },

                ticket_max_tickets: 2,
            });
            logger.log('config initilized..')();

            logger.log('booting finished..')();

            this.$router.replace({ name: 'landing' });
        },

        setConfig(config) {
            _state.set('config/setConfig', config);
        },
    },
    computed: {
        stack() {
            return process.env.VUE_APP_STACK ? process.env.VUE_APP_STACK : 'sjef'
        }
    }
}
</script>
