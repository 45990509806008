<template>
    <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import * as _state from "../lib/state";

export default {
    name: "SimpleKeyboard",
    emits: ['onchange'],
    props: {
        keyboardClass: {
            default: "simple-keyboard",
            type: String,
        },
        emailKeyboard: {
            type: Boolean,
        },

        input: {
            type: String,
        },
    },
    data: () => ({
        keyboard: null,
        bottomBarKeyboard: '.com @ {space}',
    }),
    mounted () {

        if (this.emailKeyboard === true && this.config.theme.extra_email_domains) {
            let domains = this.config.theme.extra_email_domains.split(';');

            if (domains) {
                let tempString = '@';

                domains.forEach(function(elem) {
                    tempString = tempString + ' @' + elem;
                });

                this.bottomBarKeyboard = tempString;
            }
        }

        this.keyboard = new Keyboard(this.keyboardClass, {
            onChange: this.onChange,
            onKeyPress: this.onKeyPress,
            layout: {
                'default': [
                    '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
                    '{tab} q w e r t y u i o p [ ] \\',
                    '{lock} a s d f g h j k l ; \' {enter}',
                    '{shift} z x c v b n m , . / {shift}',
                    this.bottomBarKeyboard
                ],
                'shift': [
                    '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
                    '{tab} Q W E R T Y U I O P { } |',
                    '{lock} A S D F G H J K L : " {enter}',
                    '{shift} Z X C V B N M < > ? {shift}',
                    this.bottomBarKeyboard
                ],
            },
        });
        this.keyboard.removeButtonTheme();
    },
    methods: {


        onChange (input) {
            this.$emit("onchange", input);
        },
        onKeyPress (button) {
            this.$emit("onkeypress", button);

            /**
             * If you want to handle the shift and caps lock buttons
             */
            if (button === "{shift}" || button === "{lock}") this.handleShift();
        },
        handleShift () {
            let currentLayout = this.keyboard.options.layoutName;
            let shiftToggle = currentLayout === "default" ? "shift" : "default";

            this.keyboard.setOptions({
                layoutName: shiftToggle,
            });
        },
    },
    watch: {
        input (input) {
            this.keyboard.setInput(input);
        },
    },

    computed: {
        config () {
            return _state.get('config/getConfig');
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
