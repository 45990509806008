import * as _state from "../state";

export const getRequest = (ticket, index, cutpaper = true) => {

    let config = _state.get('config/getConfig');

    var builder = new StarWebPrintBuilder();

    // Create Request
    var request = '';

    request += builder.createAlignmentElement({ position: 'center' });
    request += builder.createTextElement({ emphasis: true })
    request += builder.createTextElement({ data: '\n' });

    if (ticket.attributes.type == 'elockers') {
        request += builder.createTextElement({ emphasis: false })
        request += builder.createAlignmentElement({ position: 'center' });

        if (index === 0 || cutpaper === true) {
            request += builder.createTextElement({ emphasis: true })
            request += builder.createTextElement({ data: 'INSTRUCTIONS: \n' });
            request += builder.createTextElement({ emphasis: false })
            request += builder.createAlignmentElement({ position: 'left' });
            if (config.printer_paperwidth == '58mm') {
                request += builder.createTextElement({ data: '1. Enter the pin code and turn\n   the knob within 3 seconds\n' });
                request += builder.createTextElement({ data: '2. Close the door and turn the \n   knob back in horizontal \n   position\n' });
                request += builder.createTextElement({ data: '3. Check if door is locked \n' });
                request += builder.createTextElement({ data: 'TIP: Take a picture of this \nreceipt\n\n' });
            } else if (config.printer_paperwidth == '82mm' || config.printer_paperwidth == '72mm') {
                request += builder.createTextElement({ data: '1. Enter the pin code and turn the knob \n   within 3 seconds\n' });
                request += builder.createTextElement({ data: '2. Close the door and turn the knob back in \n   horizontal position \n' });
                request += builder.createTextElement({ data: '3. Check if door is locked \n' });
                request += builder.createTextElement({ data: 'TIP: Take a picture of this receipt\n\n\n' });
            }
            request += builder.createAlignmentElement({ position: 'center' });
        }
        request += builder.createTextElement({ data: ticket.attributes.details.event_name + '\n' });
        request += builder.createTextElement({ data: ticket.attributes.details.event_date + '\n' });
        request += builder.createTextElement({ data: ticket.attributes.details.location_name + '\n' });
        request += builder.createTextElement({ data: ticket.attributes.details.area_name + '\n' });
        request += builder.createTextElement({ emphasis: true })
        request += builder.createRuledLineElement({ thickness: 'double_medium', width: 200 });
        request += builder.createTextElement({ data: 'Locker: ' + ticket.attributes.description + '\n' });
        request += builder.createTextElement({ data: 'PIN: ' + ticket.attributes.pincode + '\n' });
        request += builder.createTextElement({ data: ticket.attributes.details.type + ', ' + ticket.attributes.details.cabinet_color + '\n', emphasis: false });
        request += builder.createRuledLineElement({ thickness: 'double_medium', width: 200 });
        request += builder.createTextElement({ emphasis: true })
    }

    if (ticket.attributes.type == 'queue_yearly') {
        request += builder.createRuledLineElement({ thickness: 'double_medium', width: 200 });
        request += builder.createTextElement({ data: 'Ticketnumber: ' + ticket.attributes.ticketnumber + '\n' });
        request += builder.createRuledLineElement({ thickness: 'double_medium', width: 200 });
    }
    request += builder.createTextElement({ data: '\n' });

    if (cutpaper === true) {
        request += builder.createCutPaperElement({
            feed: true,
            type: 'partial',
        });
    }
    return request;
}
