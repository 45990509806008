<template>
    <div>
        <div v-if="status && status.printer_error" class="fixed z-100 inset-0 overflow-y-auto"
             aria-labelledby="modal-title"
             role="dialog" aria-modal="true">
            <div class="button-flexer reset-printer">
                <button type="button" @click="incrementTrigger()" class="btn btn-primary">
                    Reset Printer
                </button>
            </div>
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div
                class="modal inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div v-if="resetted === false">
                    <div class="mt-3 sm:mt-5">
                        <h3 class="mb-5" id="modal-title">
                            Printer Error
                        </h3>

                        {{ status.printer_error }}
                    </div>
                    <!--                    <div class="button-flexer">-->
                    <!--                        <div class="mt-5 sm:mt-6">-->
                    <!--                            <button type="button" @click="incrementTrigger()" class="btn btn-primary">-->
                    <!--                                Reset Printer-->
                    <!--                            </button>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>

                <div v-else>
                    <button @click="close()" type="button" class="close-button">
                        <span class="sr-only">{{ $t('translations.global.close') }}</span>
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <div class="mt-3 text-center sm:mt-5">
                        <h3 class="mb-5 text-lg leading-6 font-medium" id="modal-title">
                            Printer Resetted
                        </h3>
                    </div>
                    <div class="button-flexer">
                        <div class="mt-5">
                            <button type="button" @click="close()" class="btn btn-primary">
                                {{ $t('translations.global.close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal-manager-login ref="login" @loggedIn="reset()" />
    </div>

</template>

<script>

import * as _api from "../lib/api";

// libs
import * as _state from "../lib/state";
import * as _printer from '../lib/printer';

// components
import ModalManagerLogin from './ModalManagerLogin.vue';

export default {
    name: 'modal.status.error',
    components: {
        ModalManagerLogin,
    },
    data () {
        return {
            active: false,
            resetted: false,
            click_trigger: 0,
        };
    },

    methods: {
        open () {
            this.resetted = false;
            this.active = true;
        },

        close () {
            this.resetted = false;
            this.active = false;
        },

        incrementTrigger () {
            this.click_trigger++;

            if (this.click_trigger == 1) {
                var self = this;
                setTimeout(function () {
                    self.click_trigger = 0;
                }, 3000);
            }

            if (this.click_trigger == 5) {
                this.$refs.login.open();
            }
        },

        reset () {
            _printer.reset();
            this.resetted = true;
        },
    },
    computed: {
        status () {
            return _state.get('status/getStatus');
        },

        config () {
            return _state.get('config/getConfig');
        },
    },
};
</script>
