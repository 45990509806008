<template>
<div>
    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto modal-locker-config-missing" 
        aria-labelledby="modal-title" 
        role="dialog" 
        aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div> <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="modal inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                    <div class="">
                        <h3 class="mb-5 text-2xl leading-6 font-medium" id="modal-title">
                            {{ $t('translations.modal.lockerconfigmissing.title') }}
                        </h3>
                    </div>
                </div>
                <div class="mt-10 flex justify-start">
                    <button type="button" @click="close()" class="btn btn-secondary">
                        {{ $t('translations.modal.lockerconfigmissing.button') }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <modal-manager ref="modalmanager"></modal-manager>
</div>
</template>

<script>
import * as _state from "../lib/state";

import ModalManager from './ModalManager.vue';

export default {
    name: 'modal.lockerconfigmissing',
    components: {
        ModalManager
    },
    data () {
        return {
            // active: false,
        };
    },
    methods: {
        open() {
            this.active = true;
        },

        close() {
            this.$refs.modalmanager.login();
            // this.active = false;
        },
    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
        active () {
            return this.config.elockers_config_required == true && (!this.config.integration_elockers_event_id);
        },
    },
};
</script>
