import * as _api from './api';
import * as _log from '../lib/log';
import * as _state from "../lib/state";
import moment from 'moment';

// set the logger
const logger = _log.get('ELOCKERS');

const ticketCounter = () => {

    // get the transaction
    let transaction = _state.get('transaction/getTransaction');

    // clone lines
    let lines = JSON.parse(JSON.stringify(transaction.lines))

    // count the tickets in the lines
    return lines
        .map(line => {
            return line.tickets.filter(ticket => ticket.vendor == 'elockers');
        }).flat().length;
}

const reserve = async (type) => {
    _state.setField('status/getStatus', 'status/setStatus', 'item_adding', true);
    let config = _state.get('config/getConfig');

    let tickets_counter = ticketCounter();

    if (tickets_counter >= config.ticket_max_tickets) {
        _state.setField('status/getStatus', 'status/setStatus', 'ticket_max_tickets', true);
        _state.setField('status/getStatus', 'status/setStatus', 'item_adding', false);
        return false;
    }

    const next = config.integration_elockers_event_next;

    // reserve a locker through the api
    let response = await _api.post('elockers/reserve', {
        quantity: 1,
        type: type,
        elockers_event_id: config.integration_elockers_event_id,
        elockers_location_id: config.integration_elockers_location_id,
        elockers_area_id: config.integration_elockers_area_id,
        next,
    });


    let tickets = [];

    if (response && response.result === 'success') {

        // start timer for reservation
        if (tickets_counter == 0) {
            // _state.setField('status/getStatus', 'status/setStatus', 'elockers_timer', 10000);
            _state.setField('status/getStatus', 'status/setStatus', 'elockers_timer', 300000);
        }
        if (next === true) {
            tickets.push({
                lockernumber: null,
                ticket_id: null,
                type: type,
                vendor: 'elockers',
                details: {
                    next,
                    event_id: config.integration_elockers_event_id,
                    event_date: moment(config.integration_elockers_event_date).format('DD-MM-YYYY'),
                    event_name: config.integration_elockers_event_name,
                    location_id: config.integration_elockers_location_id,
                    location_name: config.integration_elockers_location_name,
                    area_id: config.integration_elockers_area_id,
                    area_name: config.integration_elockers_area_name,
                    cabinet_color: null,
                    type: type,
                },
            });
        } else {
            for (const ticket of response.data) {
                tickets.push({
                    lockernumber: ticket.lockernumber,
                    ticket_id: ticket.ticket_id,
                    type: ticket.type,
                    vendor: 'elockers',
                    details: {
                        next,
                        event_id: ticket.event_id,
                        event_date: ticket.event_date,
                        event_name: ticket.event_name,
                        location_id: ticket.location_id,
                        location_name: ticket.location_name,
                        area_id: ticket.area_id,
                        area_name: ticket.area_name,
                        cabinet_color: ticket.cabinet_color,
                        type: ticket.type,
                    }
                });
                logger.log('ticket reserved', ticket)();
            }
        }
    }
    else {
        logger.log('ticket not reserved')();

        _state.setField('status/getStatus', 'status/setStatus', 'ticket_unavailable_ticket', true);
        // status.ticket_unavailable_ticket = true;

        // _state.set('status/setStatus', status);
    }

    _state.setField('status/getStatus', 'status/setStatus', 'item_adding', false);

    return tickets.length > 0 ? tickets : false;
}

const release = async (ticket_ids) => {

    // let config = _state.get('config/getConfig');

    // reserve a locker through the api
    let response = await _api.post('elockers/release', {
        ticket_ids: ticket_ids,
    });

    if (response && response.result === 'success') {
        logger.log('tickets released', response)();
    }
    else {
        logger.log('tickets not released')();
    }
    return response;
}

export {
    release,
    reserve,
    ticketCounter,
}
