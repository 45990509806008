<template>

    <!-- todo: remove -->
    <a v-if="!this.hideFakeScan" @click="barcodeScanned('001')" class=" btn btn-secondary">
        Fake scan (barcode: 001)
    </a>

    <!-- handle the item: show popup or add to basket -->
    <item ref="item" />

    <!-- modals -->
    <modal-item-not-found ref="modalitemnotfound" />
    <modal-item-unavailable ref="modalitemunavailable" />

</template>

<script>
    import * as _api from '../lib/api';
    import * as _state from "../lib/state";
    import * as _log from '../lib/log';

    // mixins
    import BarcodeReader from '../mixins/barcode.reader.js';

    // components
    import Item from '../components/Item.vue';
    import ModalItemNotFound from '../components/ModalItemNotFound.vue';
    import ModalItemUnavailable from '../components/ModalItemUnavailable.vue';

    // set the logger
    const logger = _log.get('BarcodeScanner');

    export default {
        name: 'scanner',
        emit:['initTransaction'],
        props: ['hideFakeScan', 'scanDisabled', 'initTransaction'],
        mixins: [BarcodeReader],
        components: {
            Item,
            ModalItemNotFound,
            ModalItemUnavailable,
        },
        data() {
            return {
                loading: false,
            }
        },

        mounted() {
            this.initBarcodeScanner()
        },
        unmounted() {
            this.destroyBarcodeScanner()
        },

        methods: {

            async barcodeScanned(barcode) {
                if(!this.scanDisabled){
                    this.$refs.modalitemnotfound.close();
                    this.$refs.modalitemunavailable.close();

                    // search for item by barcode to api
                    let response = await _api.post('items/search-barcode', {
                        barcode: barcode,
                    });

                    // handle the response
                    if (response && response.data && response.data.id) {

                        // set item
                        let item = response.data;

                        logger.log('item found', item)();

                        // is available
                        if (item.attributes.available_in_inventory === false) {
                            this.$refs.modalitemunavailable.open();

                            return;
                        }

                        if (this.initTransaction) {
                            await this.$emit('initTransaction')
                        }
                        // add the item to the basket
                        this.$refs.item.addItem(item);

                        return true;
                    }
                    logger.log('item not found', item)();

                    // open the item not found modal
                    this.$refs.modalitemnotfound.open();
                }
            },
        },
        computed: {
            hasFakeScan() {
                return process.env.VUE_APP_DEVELOPMENT == 'true' ? true : false
            }
        },
    }
</script>