<template>
    <div class="modal modal-full transform padding-wrapper payment-modal">
        <div class="modal-inner">
            <h3 class="text-5xl text-center mb-5"> {{ countDown }} </h3>
            <h1 class="text-center">{{ $t('translations.payment.failed.title') }}</h1>
            <div class="payment-icon failed">
                <svg width="270px" height="270px" viewBox="0 0 270 270" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Group 3</title>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Group-3" transform="translate(4.000000, 4.000000)">
                            <circle id="Oval" stroke="#" stroke-width="8" cx="131" cy="131" r="131"></circle>
                            <rect id="Rectangle" fill="#" transform="translate(131.500000, 130.500000) rotate(45.000000) translate(-131.500000, -130.500000) " x="48" y="124" width="167" height="13" rx="6.5"></rect>
                            <rect id="Rectangle" fill="#" transform="translate(130.500000, 130.500000) rotate(-45.000000) translate(-130.500000, -130.500000) " x="47" y="124" width="167" height="13" rx="6.5"></rect>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="button-flexer">
                <button @click="$emit('cancel')" type="button"
                        class="btn btn-secondary">
                    {{ $t('translations.payment.failed.button-cancelpayment') }}
                </button>
                <button @click="$emit('tryagain')" type="button"
                        class="btn btn-primary">
                    {{ $t('translations.payment.failed.button-tryagain') }}
                </button>
            </div>
        </div>
    </div>

</template>

<script>

import * as _state from "../lib/state";

export default {
    emits: ['tryagain', 'cancel'],
    name: 'payment.failed',
    data () {
        return {
            countDown: 10,
        };
    },
    mounted() {
        if (this.config.theme.device === 'S1U2') {
            this.$emit('cancel');
        }
    },
    watch: {
        countDown: {
            handler(value) {
                if(value > 0) {
                    setTimeout(() => {
                        this.countDown--;
                    }, 1000);
                }else if(value <= 0){
                    this.$emit('cancel');
                }
            },
            immediate: true
        }
    },
    computed: {
        config () {
            return _state.get('config/getConfig');
        },
    }
};
</script>
