export default {
    namespaced: true,
    state: {
        config: false
    },

    getters: {
        getConfig: state => state.config ? state.config : false,
    },

    mutations: {
        CONFIG(state, config) {
            state.config = config;
        },
    },

    actions: {
        setConfig({ commit }, config) {
            commit('CONFIG', config);
        },
    }
}
