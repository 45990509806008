export default {
    namespaced: true,
    state: {
        transaction: {}
    },

    getters: {
        getTransaction: state => state.transaction,
    },

    mutations: {
        TRANSACTION(state, transaction) {
            state.transaction = transaction;
        },
    },

    actions: {
        setTransaction({ commit }, transaction) {
            commit('TRANSACTION', transaction);
        },
    }
}
