<template>
    <router-view/>
</template>

<script>

if (process.env.VUE_APP_FEEDBACK == 1) {
    window.onUsersnapCXLoad = function(api) {
        api.init();
    }

    var script = document.createElement('script');
    script.async = 1;
    script.src = 'https://widget.usersnap.com/load/3b9c8c84-dd97-42bd-98bb-12c8c1e22078?onload=onUsersnapCXLoad';
    document.getElementsByTagName('head')[0].appendChild(script);
}

export default {
    name: 'App',
}


// disable browser back
history.pushState(null, null, location.href);
window.onpopstate = function () {
    history.go(1);
};
</script>