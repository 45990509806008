import * as _state from "../state";
import * as _locker_receipt from './locker';

export const getRequest = (transaction, copy = false) => {

    let config = _state.get('config/getConfig');

    var builder = new StarWebPrintBuilder();

    // Create Request
    var request = '';

    request += builder.createInitializationElement();

    // tickets
    if (config.receipt_type === 'default' || config.receipt_type === 'includes_eft' || config.receipt_type === 'tickets_only') {
        if (transaction.relationships.tickets && transaction.relationships.tickets.length) {
            transaction.relationships.tickets.forEach(function (ticket, index) {
                request += _locker_receipt.getRequest(ticket, index, true);
            })
        }
        if (config.receipt_type === 'tickets_only') {
            return request;
        }
    }

    request += builder.createTextElement({ characterspace: 2 });
    request += builder.createAlignmentElement({ position: 'center' });

    request += builder.createTextElement({ emphasis: true });

    let self = this

    if (copy == true) {
        request += builder.createTextElement({ data: '******* COPY *******\n\n' });
    }
    request += builder.createTextElement({ data: transaction.relationships.location.attributes.name });

    if (transaction.relationships.area) {
        request += builder.createTextElement({ data: transaction.relationships.area.attributes.name });
    }

    request += builder.createTextElement({ data: '\n' });
    request += builder.createTextElement({ data: (transaction.relationships.location.attributes.streetname ? transaction.relationships.location.attributes.streetname : '') + ' ' + (transaction.relationships.location.attributes.housenumber ? transaction.relationships.location.attributes.housenumber : '') });
    request += builder.createTextElement({ data: '\n' });
    request += builder.createTextElement({ data: (transaction.relationships.location.attributes.zipcode ? transaction.relationships.location.attributes.zipcode : '') + ' ' + (transaction.relationships.location.attributes.city ? transaction.relationships.location.attributes.city : '') });
    request += builder.createTextElement({ data: '\n' });
    request += builder.createTextElement({ data: transaction.relationships.location.attributes.email });
    request += builder.createTextElement({ data: '\n' });
    request += builder.createTextElement({ data: transaction.relationships.location.attributes.phone_number });
    request += builder.createTextElement({ data: '\n' });
    request += builder.createTextElement({ emphasis: false });

    if (transaction.relationships.table) {
        request += builder.createTextElement({ emphasis: false });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ data: transaction.relationships.table.attributes.name });
        request += builder.createTextElement({ data: '\n' });
    }

    // tickets
    if (config.receipt_type === 'includes_tickets' || config.receipt_type === 'includes_eft_tickets') {
        if (transaction.relationships.tickets && transaction.relationships.tickets.length) {

            request += builder.createAlignmentElement({ position: 'center' });
            request += builder.createTextElement({ emphasis: true })

            transaction.relationships.tickets.forEach(function (ticket, index) {
                request += _locker_receipt.getRequest(ticket, index, false);
            })
        }
    }

    request += builder.createAlignmentElement({ position: 'left' });
    request += builder.createTextElement({ emphasis: false })
    request += builder.createTextElement({ data: '\n' });
    request += builder.createTextElement({ data: '\n' });

    if (transaction.attributes.type == 'cash_inout' && transaction.relationships.cashfunction) {
        request += builder.createTextElement({ emphasis: true });
        request += builder.createTextElement({ data: 'KAS Functie: ' + transaction.relationships.cashfunction.attributes.description });
        request += builder.createTextElement({ data: '\n' });
        request += builder.createTextElement({ emphasis: false });
    }

    // Headings
    if (transaction.attributes.type != 'cash_inout') {
        if (config.printer_paperwidth == '58mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: format_string('AANT', 5) + '  ' + format_string('OMSCHR', 11) + '  ' + format_string('TOTAAL', 7, 'right')
            });
        } else if (config.printer_paperwidth == '82mm' || config.printer_paperwidth == '72mm') {
            request += builder.createTextElement({
                emphasis: true,
                data: format_string('AANT', 5) + '  ' + format_string('OMSCHRIJVING', 25) + '  ' + format_string('TOTAAL', 7, 'right')
            });
        }
    }

    request += builder.createTextElement({
        data: '\n',
        emphasis: false
    });

    // Lines
    let lines = transaction.relationships.lines

    if (config.printer_paperwidth == '58mm') {
        lines.forEach(function (line) {
            if (line.attributes.hidden_receipt) {
                return
            }
            request += builder.createTextElement({
                // data: format_string(parseFloat(line.attributes.quantity).toFixed(2), 5, 'left') + '  ' + (line.attributes.addon ? ' ' : '') + format_string(line.attributes.description, (line.attributes.addon ? 10 : 11)) + '  ' + format_string(parseFloat(line.attributes.total).toFixed(2), 6, 'right') + '\n'
                data: format_string(parseFloat(line.attributes.quantity), 5, 'left') + '  ' + (line.attributes.addon ? ' ' : '') + format_string(line.attributes.description, (line.attributes.addon ? 10 : 11)) + '  ' + format_string(parseFloat(line.attributes.total).toFixed(2), 7, 'right') + '\n'
                // data: (line.attributes.addon ? ' ' : '') + format_string(line.attributes.description, (line.attributes.addon ? 10 : 11)) + '  ' + format_string(parseFloat(line.attributes.quantity).toFixed(2), 5, 'right') + '  ' + format_string(parseFloat(line.attributes.total).toFixed(2), 6, 'right') + '\n'
            });

            // variant
            if (line.attributes.variant) {
                request += builder.createTextElement({
                    data: ' ' + format_string(line.attributes.variant, 20) + '\n'
                });
            }
            // discount
            if (line.attributes.discount_amount > 0) {
                request += builder.createTextElement({
                    data: ' Korting ' + parseFloat(line.attributes.discount_amount).toFixed(2) + '\n'
                });
            }

            // kitchengroceries
            let kitchen_groceries = Object.entries(line.attributes.kitchen_groceries)

            kitchen_groceries.forEach(function (value) {
                request += builder.createTextElement({
                    data: ' ' + format_string(value[0], 7) + ': ' + value[1] + '\n'
                });
            })

            // wallettoken
            if (line.attributes.cardnumber) {
                request += builder.createTextElement({
                    data: ' Kaartnummer: ' + line.attributes.cardnumber + '\n'
                });
            }
        })

    } else if (config.printer_paperwidth == '82mm' || config.printer_paperwidth == '72mm') {
        lines.forEach(function (line) {
            if (line.attributes.hidden_receipt) {
                return
            }
            request += builder.createTextElement({
                // data: (line.attributes.addon ? ' ' : '') + format_string(line.attributes.description, (line.attributes.addon ? 16 : 17)) + '  ' + format_string(parseFloat(line.attributes.quantity).toFixed(2), 5, 'right') + '  ' + format_string(parseFloat(line.attributes.total).toFixed(2), 6, 'right') + '\n'
                data: format_string(parseFloat(line.attributes.quantity), 5, 'left') + '  ' + (line.attributes.addon ? ' ' : '') + format_string(line.attributes.description, (line.attributes.addon ? 25 : 26)) + '  ' + format_string(parseFloat(line.attributes.total).toFixed(2), 6, 'right') + '\n'

            });

            // variant
            if (line.attributes.variant) {
                request += builder.createTextElement({
                    data: ' ' + format_string(line.attributes.variant, 25) + '\n'
                });
            }

            // discount
            if (line.attributes.discount_amount > 0) {
                request += builder.createTextElement({
                    data: ' Korting ' + parseFloat(line.attributes.discount_amount).toFixed(2) + '\n'
                });
            }

            // kitchengroceries
            let kitchen_groceries = Object.entries(line.attributes.kitchen_groceries)

            kitchen_groceries.forEach(function (value) {
                request += builder.createTextElement({
                    data: ' ' + format_string(value[0], 7) + ': ' + value[1] + '\n'
                });
            })
        })
    }

    request += builder.createRuledLineElement({ thickness: 'double_thin' });

    //  totals
    if (config.printer_paperwidth == '58mm') {
        request += builder.createTextElement({
            emphasis: true,
            data: format_string('TOTAAL', 16) + '  ' + format_string(parseFloat(transaction.attributes.total).toFixed(2), 9, 'right') + '\n'
        });
    } else if (config.printer_paperwidth == '82mm' || config.printer_paperwidth == '72mm') {
        request += builder.createTextElement({
            emphasis: true,
            data: format_string('TOTAAL', 30) + '  ' + format_string(parseFloat(transaction.attributes.total).toFixed(2), 9, 'right') + '\n'
        });
    }


    request += builder.createTextElement({emphasis: false});

    // Balancedue
    if (transaction.attributes.balancedue > 0) {
        if (config.printer_paperwidth == '58mm') {
            request += builder.createTextElement({
                data: format_string('Nog te betalen', 18) + '  ' + format_string(parseFloat(transaction.attributes.balancedue).toFixed(2), 7, 'right') + '\n'
            });
        } else if (config.printer_paperwidth == '82mm' || config.printer_paperwidth == '72mm') {
            request += builder.createTextElement({
                data: format_string('Nog te betalen', 25) + '  ' + format_string(parseFloat(transaction.attributes.balancedue).toFixed(2), 7, 'right') + '\n'
            });
        }
        request += builder.createTextElement({ data: '\n' });
    }
    else if (transaction.attributes.balancedue < 0) {
        if (config.printer_paperwidth == '58mm') {
            request += builder.createTextElement({
                data: format_string('Wisselgeld', 18) + '  ' + format_string(parseFloat(transaction.attributes.balancedue).toFixed(2), 7, 'right') + '\n'
            });
        } else if (config.printer_paperwidth == '82mm' || config.printer_paperwidth == '72mm') {
            request += builder.createTextElement({
                data: format_string('Wisselgeld', 25) + '  ' + format_string(parseFloat(transaction.attributes.balancedue).toFixed(2), 7, 'right') + '\n'
            });
        }
    }
    request += builder.createTextElement({ data: '\n' });

    // Taxrates
    if (config.printer_paperwidth == '58mm') {
        for (let index in transaction.relationships.taxrates) {
            request += builder.createTextElement({
                data:
                format_string('BTW ' + index, 7) + ' ' +
                // format_string(parseFloat(transaction.relationships.taxrates[index]['amount_included']).toFixed(2) + 'I', 7) +
                format_string(parseFloat(transaction.relationships.taxrates[index]['amount_excluded']).toFixed(2), 10, 'right') +
                format_string(parseFloat(transaction.relationships.taxrates[index]['taxes']).toFixed(2), 9, 'right')
            });
        }
    } else if (config.printer_paperwidth == '82mm' || config.printer_paperwidth == '72mm') {
        for (let index in transaction.relationships.taxrates) {
            request += builder.createTextElement({
                data:
                format_string('BTW ' + index, 18) + ' ' +
                // format_string(parseFloat(transaction.relationships.taxrates[index]['amount_included']).toFixed(2) + 'I', 7) +
                format_string(parseFloat(transaction.relationships.taxrates[index]['amount_excluded']).toFixed(2), 10) +
                format_string(parseFloat(transaction.relationships.taxrates[index]['taxes']).toFixed(2), 12, 'right')
                // data: format_string('', 20) + '  ' + format_string('BTW ' + index, 10) + '  ' + format_string(parseFloat(transaction.relationships.taxes[index]).toFixed(2), 7, 'right') + '\n'
            });
        }
    }
    request += builder.createTextElement({ data: '\n\n' });

    // Payments
    request += builder.createTextElement({ data: 'Betalingen\n', emphasis: true });
    request += builder.createTextElement({ emphasis: false });

    if (config.printer_paperwidth == '58mm') {
        transaction.relationships.payments.filter(pm => {
            return pm.attributes.status == 'paid';
        }).forEach(function (payment) {
            request += builder.createTextElement({
                data: format_string(payment.attributes.paymenttype, 16, 'left') + '  '  + format_string(parseFloat(payment.attributes.amount).toFixed(2), 9, 'right') + '\n'
            });
        });
    }
    else if (config.printer_paperwidth == '82mm' || config.printer_paperwidth == '72mm') {
        transaction.relationships.payments.filter(pm => {
            return pm.attributes.status == 'paid';
        }).forEach(function (payment) {
            request += builder.createTextElement({
                data: format_string(payment.attributes.paymenttype, 30, 'left') + '  '  + format_string(parseFloat(payment.attributes.amount).toFixed(2), 9, 'right') + '\n'
            });
        });
    }
    request += builder.createTextElement({ data: '\n' });
    request += builder.createTextElement({ data: '\n' });

    //  subtotal
    if (transaction.relationships.subtotal) {
        request += builder.createRuledLineElement({ thickness: 'double_thin' });
        request += builder.createAlignmentElement({ position: 'center' });
        request += builder.createTextElement({
            emphasis: true,
            data: transaction.relationships.subtotal.attributes.name + '\n'
        });
        request += builder.createRuledLineElement({ thickness: 'double_thin' });
        request += builder.createTextElement({
            data: '\n',
            emphasis: false
        });
    }

    request += builder.createTextElement({ data: '\n' });
    request += builder.createAlignmentElement({ position: 'center' });
    request += builder.createTextElement({ emphasis: true });
    request += builder.createTextElement({ data: 'Transactienr. ' +transaction.attributes.transactionnumber + '\n' });


    // timestamp
    request += builder.createTextElement({
        emphasis: false
    });
    request += builder.createTextElement({ data: transaction.attributes.time + ' ' + transaction.attributes.date + '\n' });

    // eft
    if (config.receipt_type === 'includes_eft' || config.receipt_type === 'includes_eft_tickets') {
        request += builder.createAlignmentElement({ position: 'left' });

        transaction.relationships.payments.forEach(function (payment) {
            if (payment.attributes.eft_receipt_customer) {
                request += builder.createTextElement({ data: '\n' });
                request += builder.createRuledLineElement({ thickness: 'thin' });
                JSON.parse(payment.attributes.eft_receipt_customer).forEach(function (line) {
                    request += builder.createTextElement({ data: line + '\n' });
                })
                request += builder.createTextElement({ data: '\n' });
            }
        })
    }

    request += builder.createCutPaperElement({
        feed: true,
        type: 'full',//store.printer.autocutter,
    });

    return request;
}




function format_string(value, length, align = 'left') {
    let str = value.toString();

    while (str.length < length) {
        if (align == 'right') {
            str = ' ' + str;
        } else {
            str = str + ' ';
        }
    }
    if (str.length <= length) {
        return str
    }
    return str.slice(0, length)
    // return string;

}
