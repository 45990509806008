<template>
    <div v-if="active" class="fixed z-100 inset-0 overflow-y-auto modal-cancel" aria-labelledby="modal-title" role="dialog"
         aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div
                class="modal modal-full transform padding-wrapper">
                <div class="modal-inner">
                    <div>
                        <button @click="close()" type="button" class="close-button">
                            <span class="sr-only">{{ $t('translations.global.close') }}</span>
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <h1 class="" id="modal-title">
                            {{ $t('translations.modal.cancel.title') }}
                        </h1>
                        <p>{{ $t('translations.modal.cancel.description') }}</p>
                    </div>
                    <div class="button-flexer">
                        <button type="button" @click="cancelTransaction()"
                                class="btn btn-secondary">
                            {{ $t('translations.modal.cancel.button-cancel') }} ({{ countDown }})
                        </button>
                        <button type="button" @click="close()"
                                class="btn btn-primary">
                            {{ $t('translations.modal.cancel.button-close') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// libs
import * as _log from '../lib/log';
import * as _payment from '../lib/payment';
import * as _transaction from '../lib/transaction';

// set the logger
const logger = _log.get('ModalCancel');

export default {
    name: 'modal.cancel',
    data () {
        return {
            active: false,
            countDown: false,
        };
    },
    methods: {
        open () {
            this.active = true;
            this.countDown = 15;
        },

        close () {
            this.active = false;
            this.countDown = false;
        },

        cancelTransaction () {
            logger.log('transaction canceled');

            _transaction.cancel(true);
            _payment.clear();

            this.$router.replace({ name: 'landing' });
        },
    },
    computed: {
         config () {
            return _state.get('config/getConfig');
        },
    },
    watch: {
        countDown: {
            handler(value) {
                if(value > 0) {
                    setTimeout(() => {
                        this.countDown--;
                    }, 1000);
                } else if(value <= 0 && this.active) {
                    this.cancelTransaction();
                }
            },
            immediate: true
        }
    },
};
</script>
