import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

import config from './config';
import payment from './payment';
import status from './status';
import token from './token';
import transaction from './transaction';

export default createStore({
    plugins: [createPersistedState()],
    modules: {
        config,
        payment,
        status,
        token,
        transaction,
    },
})
